import * as React from 'react';
import {DotTimeline, TimelineDate, TimelineElement, TimelineLabel} from 'src/components/DotTimeline';
import {Column, Row} from 'src/components/Grid';
import {UnstyledList} from 'src/components/List';
import {
  PageSection, PageSectionContent, PageSectionHeader,
  PageSectionParagraph, PageSectionSideImage, PageSectionSubHeader,
} from 'src/components/PageSection';
import {Reg506c} from 'src/components/Reg506c';

export const AugustaStreetLofts = () => (
  <>
    <PageSection>
      <PageSectionHeader>Investment Objective</PageSectionHeader>
      <PageSectionContent>
        <PageSectionSideImage src={'apartment/img1.jpg'}/>
        <PageSectionParagraph centered={true}>
          The Augusta Street Lofts is a ground-up development of a 262-unit luxury rental apartment
          complex in the central business district of San Antonio, Texas. The property’s infill
          location places the residents between San Antonio’s central business district (CBD)
          and the vibrant Pearl District.
          <br/>
          <br/>
          Residents will have access to major employment, retail and entertainment venues.
          The Project provides a best-in-class set of community amenities, including a large
          resort-style pool, courtyard with pergolas and grilling stations, a state-of-the-art
          two-story fitness center, and chic resident lounge that caters to the needs and
          desires of the young professional tenants.
          <br/>
          <br/>
          NHK is offering this project as both an equity investment partnership and a debt
          investment partnership with the goal of providing investors the choice of
          structure that meets their financial planning/goals.
        </PageSectionParagraph>
      </PageSectionContent>
    </PageSection>
    <PageSection alternate={true}>
      <PageSectionHeader>Project Timeline</PageSectionHeader>
      <PageSectionSubHeader>Tentative</PageSectionSubHeader>
      <DotTimeline>
        <TimelineElement>
          <TimelineLabel>
            Construction<br/>
            Commence
          </TimelineLabel>
          <TimelineDate>
            Mar 2019
          </TimelineDate>
        </TimelineElement>
        <TimelineElement>
          <TimelineLabel>
            Construction<br/>
            Completion
          </TimelineLabel>
          <TimelineDate>
            Mar 2020
          </TimelineDate>
        </TimelineElement>
        <TimelineElement>
          <TimelineLabel>
            &nbsp;<br/>
            Stabilization
          </TimelineLabel>
          <TimelineDate>
            Sept 2021
          </TimelineDate>
        </TimelineElement>
        <TimelineElement>
          <TimelineLabel>
            &nbsp;<br/>
            Exit
          </TimelineLabel>
          <TimelineDate>
            Sept 2023
          </TimelineDate>
        </TimelineElement>
      </DotTimeline>
    </PageSection>
    <PageSection>
      <PageSectionHeader>Project Developer</PageSectionHeader>
      <PageSectionContent>
        <div>
          <PageSectionParagraph centered={true}>
            Stillwater Capital Investments, LLC is a fully-integrated, private real
            estate company with expertise in sourcing, underwriting, developing,
            acquiring, and managing a wide spectrum of real estate products.
            For additional information please visit
            {' '}
            <a href='http://www.stillwatercap.com' target='_blank' rel='noreferrer'>
              Stillwater Capital's Website
            </a>
          </PageSectionParagraph>
          <br/>
        </div>
        <PageSectionSideImage src={'stock/city.jpg'} flip={true}/>
      </PageSectionContent>
    </PageSection>
    <PageSection alternateDark={true}>
      <Reg506c>
        <>
          <Row>
            <Column>
              <PageSectionHeader>Equity Investment<br/>Partnership</PageSectionHeader>
              <PageSectionContent>
                <UnstyledList>
                  <li>Partnership: NHK Augusta, LP</li>
                  <li>Target Hold Period: 5 years</li>
                  <li>Capital Raise: $10,000,000</li>
                  <li>Unit Price: $50,000</li>
                  <li>Minimum Investment: $100,000 (2 Units)</li>
                  <li>
                    <strong>Target Return</strong>
                    <ul>
                      <li>IIR: 15.5%</li>
                      <li>Equity Multiple: 1.54x</li>
                    </ul>
                  </li>
                  <li>
                  Exit Strategy: Sale of the property
                  </li>
                </UnstyledList>
              </PageSectionContent>
            </Column>
            <Column>
              <PageSectionHeader>Debt Investment<br/>Partnership</PageSectionHeader>
              <PageSectionContent>
                <UnstyledList>
                  <li>Partnership: NHK Augusta Mezz, LP</li>
                  <li>Target Hold Period: 5 years</li>
                  <li>Capital Raise: $8,500,000</li>
                  <li>Unit Price: $50,000</li>
                  <li>Minimum Investment: $100,000 (2 Units)</li>
                  <li>
                    <strong>Target Return: 5.7% / year</strong>
                  </li>
                  <li>
                  Exit Strategy: 5 years loan maturity term
                  </li>
                </UnstyledList>
              </PageSectionContent>
            </Column>
          </Row>
          <br/>
          <br/>
          <Row>
            <Column>
              <PageSectionHeader>Project Summary</PageSectionHeader>
              <UnstyledList>
                <li>Developer: Stillwater Capital Investments, LLC</li>
                <li>Location: 819 Augusta Street, San Antionio, TX 78215</li>
                <li>Rentable Area: 208,754<sup>2</sup> (19,394 m<sup>2</sup>)</li>
                <li>Land Area: 2.6 acres (10,522 m<sup>2</sup>)</li>
              </UnstyledList>
            </Column>
            <Column/>
          </Row>
        </>
      </Reg506c>
    </PageSection>
  </>
);
