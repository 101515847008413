import * as React from 'react';
import {Link as BaseLink, LinkProps} from 'react-router-dom';

export interface ILinkProps extends LinkProps {
  scrollToTop?: boolean;
}

export const Link = ({scrollToTop, to, ...props}: ILinkProps) => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  };
  return (
    <BaseLink to={to} onClick={onClick} {...props}/>
  );
};
