import moment from 'moment';
import React, {useState} from 'react';

import {Highlight} from 'src/components/Highlight';
import {Img} from 'src/components/Img';
import {Link} from 'src/components/Link';
import {NHKLocationBar} from 'src/components/NHKLocationBar';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {ndaIaicPort} from 'src/lib/isLive';
import styled from 'src/theme';
import {Footer} from './Footer';

const FormContainer = styled.div`
  width: 1280px;
  margin: 0 auto;
  padding: 30px 15px;
  border-radius: 15px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    width: 600px;
  }

  background: #e8e8e8;
  box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
  max-width: 90%;

  p {
    margin-bottom: 1rem;
  }

  ol {
    li {
      margin-bottom: 1rem;
    }
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
      }
    }
  }
`;

const StyledInput = styled.input`
  font-size: 1.3rem;
  margin: 0.25em 5px;
  border: 0.1rem inset rgba(67, 70, 75);
  border-radius: 5px;
  background: #f9f9f9;
  color: black;
  ${(props): string => props.theme.regularFont}
  font-weight: 500;
`;

const InlineLabel = styled.label`
  font-size: 1rem;
  font-style: italic;
  color: #606060;
  margin: 0 5px;
`;

const BlockLabel = styled.label`
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
  input {
    display: block;
    width: 500px;
    max-width: 90%;
    line-height: 2.5rem;
  }
  margin: 1rem 0;
`;

const SigningContainer = styled.div`
  padding: 0rem 6.0rem 0rem 6.0rem;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
  margin: 2rem 0;
  width: 100%;
  max-width: 100%;
`;

interface IFormInputProps {
  label?: string;
  autoComplete?: string;
  set?: (x: any) => void;
  value: any;
  disabled?: boolean;
  block?: boolean;
}

const FormInput = ({label, autoComplete, value, set, disabled}: IFormInputProps): JSX.Element => {
  const onChange = (e: any): void => {
    set(e.target.value);
  };

  return (
    <InlineLabel>
      <StyledInput autoComplete={autoComplete} value={value} onChange={onChange} disabled={disabled}/>
      {label}
    </InlineLabel>
  );
};

const BlockInput = ({label, autoComplete, value, set, disabled}: IFormInputProps): JSX.Element => {
  const onChange = (e: any): void => {
    set(e.target.value);
  };

  return (
    <BlockLabel>
      {label}
      <StyledInput autoComplete={autoComplete} value={value} onChange={onChange} disabled={disabled}/>
    </BlockLabel>
  );
};

const SubmitButton = styled.button`
  padding: 12px;
  width: 100%;
  max-width: 100%;
  background: rgb(198, 160, 71);
  border-radius: 0.6em;
  border: 1px solid transparent;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 0.25em 0;
  cursor: pointer;
  color: white;
  font-size: 1.3rem;

  &:disabled {
    background: rgba(134, 109, 51, 0.8);
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
    background: rgb(208, 168, 73);
  }
`;

const ErrorSuccessText = styled.div`
  ${(props): string => props.theme.regularFont}
  text-align: center;
  color: rgba(255, 25, 25, 1);
  width: 100%;
`;

export const NDAPage = ({noImage}: {noImage?: boolean}): JSX.Element => {
  const [name, setName] = useState('');
  const [date, setDate] = useState(moment().format('MMMM DD, YYYY'));
  const [signature, setSignature] = useState('');
  const [title, setTitle] = useState('');
  const [business, setBusiness] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [phone, setPhone] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [email, setEmail] = useState('');

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formKey, setFormKey] = useState('');

  const submit = async (e: Event): Promise<void> => {
    error && setError('');
    e.preventDefault();
    setSubmitting(true);

    const missingFields = [];
    !name.length && missingFields.push('Name');
    !signature.length && missingFields.push('Signature');
    !phone.length && missingFields.push('Phone Number');
    !email.length && missingFields.push('Email Address');
    !address1.length&& missingFields.push('Address');
    if (missingFields.length) {
      setError(`${missingFields.join(', ')} ${missingFields.length > 1 ? 'are' : 'is'} required`);
      setSubmitting(false);
      return;
    }
    if (name !== signature) {
      setError('Printed Name and Signature must match exactly');
      setSubmitting(false);
      return;
    }

    const formResults = await (await fetch(`${API_URL}:${ndaIaicPort()}/api/form/nda/submit`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          name,
          signature,
          date,
          title,
          // business,
          address1,
          address2,
          address3,
          phone,
          mobilePhone,
          email,
        },
      }),
    })).json();
    if (formResults.success) {
      setFormKey(formResults.formKey);
      setSuccess(true);
      setError(null);
    } else {
      setError(formResults.message);
    }
    setSubmitting(false);
  };

  const disabled = submitting || success;

  return (
    <>
      <PageBackground>
        {noImage ? '' :
          <Img src={'stock/dallas.png'} asDivWithBackground={true}/>
        }
        <PageSection mobileTop='0rem' desktopTop='0rem' marginTop='2.0rem' marginBottom='3.0rem'>
          <PageSectionHeader>NHK Capital Partners<br/>
            <Highlight>CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT</Highlight>
          </PageSectionHeader>
          <br/>
          {success ?
            <PageSectionParagraph centered>
              <span>Your Non-Disclosure Agreement has been successfully submitted.</span><br/>
              <span>Please continue to the Initial Accredited Investor Certification:</span>
              <Link to={`/iaic/${formKey}`}>
                <SubmitButton>
                Continue
                </SubmitButton>
              </Link>
            </PageSectionParagraph> :
            <FormContainer>
              <p>This NHK CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT (this “Agreement”) is made
              by and among NHK Capital Partners, LLC, a limited liability company organized in the State of Texas
              (“NHK”) and
              <FormInput
                disabled={disabled}
                set={setName}
                value={name}
                label='(Print Name)'
                autoComplete='firstname'
              />
             (“Recipient”), which hereby becomes effective as of
              <FormInput
                disabled={true}
                set={setDate}
                value={date}
              />.
              </p>
              <p>WHEREAS, NHK has developed certain confidential and proprietary information and know-how and anticipates
              disclosing said confidential and proprietary information and know-how to Recipient, and Recipient has
              agreed to maintain the confidentiality of the confidential and proprietary information and know-how
              hereinafter referred to as “Information” definedbelow.
              </p>
              <p>NOW, THEREFORE, in consideration of the foregoing recitals and premises and for other good and valuable
              consideration, the receipt and sufficiency of which is hereby acknowledged by the parties to this
              Agreement, it is agreed:
              </p>
              <ol>
                <li><strong><u>INFORMATION</u></strong>
                  <ol>
                    <li> Definition. As used in this Agreement, the term “Information” means any and
all information from NHK or its affiliates, including, but not limited to, program
descriptions, contracts and other documents, budgets, other financial
information, plans, files, manuals, reports and other materials provided to or
obtained by Recipient by or from NHK or its affiliates. The Information will
also include, without limitation, information pertaining to other recipients,
suppliers, products, services, methods of doing business, procedures,
marketing, strategic and other business plans, financial results and
projections, business or technical information, such as financial information or
data, marketing plans, techniques and material, concepts, business operations
and systems, pricing policies, information concerning employees, customers,
and/or vendors, trade secrets, discoveries, inventions, improvements,
research, development, know-how, concepts, designs, compositions, formulas,
prototypes, materials, written documents and manufacturing processes,
whether provided orally, visually, in writing, or in any other medium
regardless of whether such information is NHK’s property or the property of a
third party, and regardless of whether such information is specifically marked
or identified as confidential.
                    </li>
                    <li>Exceptions.
                      <ol>
                        <li> This Agreement shall not apply to Information that (A) was legally in
possession of Recipient prior to disclosure by NHK or its affiliates; (B)
is or subsequently becomes a part of the public domain through no
fault of the Recipient; (C) is subsequently disclosed to Recipient by a
third party not under any confidentiality obligation to NHK; or (D) is
independently developed by Recipient without the use of Information,
and proof of such independent development is available, as of the date
of such independent development. However, if any Information is or
becomes lawfully in the public domain, the Recipient shall refrain from
disclosing or disseminating, and safeguard the confidentiality of, any
Information which includes the identities of NHK’s other recipients.
The parties agree to comply with applicable privacy laws.
                        </li>
                        <li> This Agreement shall not be deemed to prohibit disclosures: (A)
required by applicable law, regulation, court order or subpoena,
provided, however, that in the case of disclosures required by court
order or subpoena, Recipient shall give NHK written notice prior to
making any such disclosure, and an opportunity to contest and/or seek
a protective order with respect thereto; (B) to the professional
advisors or auditors of Recipient, provided that such advisors or
auditors are obligated to maintain the confidentiality of the
information they receive; (C) to the affiliates of Recipient, provided
that Recipient shall be responsible for assuring its affiliates’
compliance with the confidentiality and non-disclosure terms hereof;
and (D) to the contractors of either party who are providing services to
such party and who are subject to binding confidentiality obligations.
If Recipient claims that certain Information falls within the scope of
one or more exceptions contained in this Section 2(b)(ii), such
exception must be demonstrated to NHK by detailed written records
specifically identifying the facts supporting the claims and not mere
general statements before disclosure. Although Recipient shall be
entitled to disclose Information in response to a court order or as
otherwise required by law, Recipient agrees to and shall notify NHK
prior to such disclosure, no more than ten (10) calendar days after the
Recipient receives such order, in order to provide NHK adequate time
to contest such order
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li><strong><u>USE.</u></strong> Recipient shall use the Information only for the purpose of evaluating the potential
of investing in an NHK affiliated limited partnership.
                </li>
                <li><strong><u>CONFIDENTIALITY.</u></strong> Recipient acknowledges and agrees that the Information is
confidential and proprietary property of NHK and constitutes a valuable trade secret of
NHK. Nothing herein shall be construed as granting Recipient any right, title, or interest
in or to the Information. Recipient agrees to hold the Information in trust and to
maintain, protect, and assure the confidentiality thereof. Except as expressly provided
above, Recipient, whether by act or omission, shall not copy, publish, disseminate,
divulge, release, furnish, or otherwise disclose the Information to any other person or 3
entity for any purpose whatsoever. Recipient shall take all reasonable steps to prevent
the unauthorized disclosure of the Information but in no event shall the Recipient
employ a standard of care less protective of NHK than the highest standard of care
employed by the Recipient in the protection of his/her/its own most valuable and
confidential Information. Recipient shall give access to the Information or a portion
thereof only to such of his/her/its employees and advisors who have a demonstrable
need to know the Information in connection with the purposes specified herein.
Recipient shall give NHK notice of any unauthorized use or disclosure of the Information
immediately upon discovery of such unauthorized use or disclosure. All of the
Information and materials furnished to Recipient by NHK, including, without limitation,
prototypes, models, documents, drawings, sketches, designs, or physical materials,
other forms of Information, and media upon which Information is stored or recorded
shall remain the property of NHK, as the case may be. Recipient will return to NHK
and/or destroy all such materials and Information and all copies thereof along with
certification of Recipient or Recipient’s authorized agent that none of the Information or
copies thereof remain in his/her/its possession within a reasonable time following
written request by NHK, not to exceed a two-week period.
                </li>
                <li><strong><u>WARRANTIES AND NON-CIRCUMVENTION.</u></strong> The Recipient warrants that the request for
confidential documents is based upon the Recipient having an interest in investing in a
NHK affiliated limited partnership. The intention of this paragraph is to protect the
confidentiality of general business structures of NHK and prevent the circumvention of
NHK’s “trade secrets.” The documents provided under this agreement are trade secrets
and as such may not be disclosed, disseminated or otherwise made available by
Recipient or the Recipient’s authorized representative, agent or legal counsel to third
parties unless allowed pursuant to the terms of this Agreement. Only NHK may elect to
waive the provisions of this paragraph by written and signed notice by NHK to
Recipient. In the event of a violation of this stipulation by Recipient and circumvention is
attempted or attained, NHK shall be entitled to seek any and all available recourse,
indemnity and remedies at law and inequity.
                </li>
                <li><strong><u>REMEDIES.</u></strong> Upon the occurrence or threatened or likely occurrence of any breach
hereof, NHK shall be entitled to: (a) temporary, preliminary and permanent equitable
and injunctive relief, it being expressly stipulated that any unauthorized disclosure shall
cause irreparable harm to NHK and that NHK could not, in such event, be made whole
solely by monetary damages; and (b) recover any and all losses, costs, expenses
(including reasonable attorneys’ fees) and damages, which are determined by a court of
competent jurisdiction to have been sustained by NHK. Recipient agrees that if there is
any unauthorized use or disclosure of the Information by any of Recipient’s employees
or any other third party with access to the Information through Recipient, Recipient will
enforce for the benefit of NHK, through litigation if necessary, all rights provided under
law to seek damages and protection from additional disclosure. It is accordingly agreed
the parties hereto that NHK, in addition to any other remedy to which it may be entitled
at law or in equity, shall be entitled to injunctive relief to prevent breach of this 4
Agreement, and to compel specific performance of this agreement without the need for
proof of actual damages. However, the forgoing remedies are cumulative and in addition
to any and all other remedies available at law or in equity which may be available and
pursued by NHK. No waiver or modification of the terms hereof shall be binding unless
in a written document signed by NHK. No waiver of any provision hereof at any time
shall operate as a waiver of any other provision or as a waiver of any subsequent breach
of the same provision. The invalidity or unenforceability of any provision hereof shall
not affect the validity or enforceability of the remaining provisions, all of which shall
continue in full force and effect.
                </li>
                <li><strong><u>DISCLAIMER.</u></strong> The Information is provided by NHK to Recipient on a “AS IS” basis. NHK
makes no warranties as to the accuracy or sufficiency of the Information provided to
Recipient hereunder and NHK shall have no liability to Recipient arising out of or
related to the Information, as far as legally possible. The Information is being provided
as a courtesy to Recipient and is intended for discussion purposes only and shall not
constitute any legal advice or representations of any kindwhatsoever.
                </li>
                <li><strong><u>OTHER AGREEMENTS.</u></strong> This Confidentiality Agreement is in addition to and not in lieu of
whatever other agreements that may exist between the parties; provided, however, in
the event of an inconsistency between this Agreement and the other agreement
regarding confidentiality and/or nondisclosure of Information, unless such conflicting
agreement is more protective of the Information for NHK, this Agreement shall control.
Recipient must review and sign the “Initial Accredited Investor Certification” attached
hereto as Appendix A. Nothing herein shall be construed as requiring either party to
enter into any further contractual or other relationship. Recipient may have executed a
previous Confidentiality and Non-Disclosure Agreement with NHK; this Confidentiality
and Non-Disclosure Agreement shall supersede any prior Confidentiality and NonDisclosure Agreement between Recipient andNHK.
                </li>
                <li><strong><u>SURVIVAL.</u></strong> Recipient’s obligations hereunder shall survive the termination of any
related contract or other relationship between theparties.
                </li>
                <li><strong><u>MISCELLANEOUS.</u></strong> This Agreement shall be binding upon the parties hereto and their
respective successors and assigns. This Agreement may not be amended or modified
except in a writing making express reference hereto, signed by bothparties.
                </li>
                <li><strong><u>GOVERNING LAW/JURISDICTION.</u></strong> This Agreement will be governed by and construed using
Texas law, without giving effect to conflict of law provisions. For any dispute arising out of or
relating to this Agreement, the Parties hereby irrevocably consent to venue and the exclusive
jurisdiction of the state and federal courts located in Dallas County, Texas.
                </li>
                <li><strong><u>COUNTERPARTS.</u></strong> This Agreement may be executed in one or more counterparts,
all such counterparts shall constitute originals, and all such counterparts shall
constitute a single agreement between the Parties hereto.
                </li>
              </ol>
              <p>IN WITNESS WHEREOF, the undersigned have executed this Confidentiality and
Non- Disclosure Agreement as of
              <FormInput
                disabled={true}
                set={setDate}
                value={date}
              />.
              </p>
              <SigningContainer>
                <h2>RECIPIENT:</h2>
                <p>I agree that this Confidentiality and Non-Disclosure Agreement may be electronically signed. I agree that the electronic signatures
                   appearing on this agreement are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility
                    and have the same force and effect. I recognize that I have the option to opt out and sign with a paper signature instead.</p>
                <BlockInput disabled={disabled} set={setSignature} value={signature} label='Signature: '/>
                <BlockInput disabled={disabled} set={setName} value={name} label='Print Name:' autoComplete='firstname'/>
                <BlockInput disabled={disabled} set={setTitle} value={title} label='Title (Mr./Ms.):' autoComplete='title'/>
                {/* <BlockInput disabled={disabled} set={setBusiness} value={business}
                  label='Authorized to Sign on Behalf of (Business/Firm Name):'/> */}
                <BlockInput disabled={disabled} set={setAddress1} value={address1} label='Address:' autoComplete='address1'/>
                <BlockInput disabled={disabled} set={setAddress2} value={address2} autoComplete='address2'/>
                <BlockInput disabled={disabled} set={setAddress3} value={address3}/>
                <BlockInput disabled={disabled} set={setPhone} value={phone} label='Phone:' autoComplete='tel'/>
                <BlockInput disabled={disabled} set={setMobilePhone} value={mobilePhone} label='Mobile Phone:'/>
                <BlockInput disabled={disabled} set={setEmail} value={email} label='Email:' autoComplete='email'/>
              </SigningContainer>
              <ErrorSuccessText>
                {error ? error : ''}
              </ErrorSuccessText>
              <SubmitButton disabled={disabled} onClick={submit}>{submitting ? 'Please wait...' : 'Submit'}</SubmitButton>
            </FormContainer>
          }
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <NHKLocationBar/>
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <Footer/>
        </PageSection>
      </PageBackground>
    </>
  );
};
