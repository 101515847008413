import * as React from 'react';
import {hot} from 'react-hot-loader';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {RouteProps} from 'react-router';
import {GlobalStyle} from 'src/GlobalStyle';
import {Header} from 'src/templates/Header';
import {theme, ThemeProvider} from 'src/theme';
import {ContentArea} from './components/ContentArea';
import {AboutUsPage} from './templates/AboutUsPage';
import {BusinessPage} from './templates/BusinessPage';
import {ContactPage} from './templates/ContactPage';
import {GhostRead} from './templates/GhostRead';
import {IndexPage} from './templates/IndexPage';
import {InvestmentsPage} from './templates/InvestmentsPage';
import {NewsPage} from './templates/NewsPage';
import {OurFirmPage} from './templates/OurFirmPage';
import {OurTeamPage} from './templates/OurteamPage';
import {PortfolioPage} from './templates/Portfolio';
import {PressKitPage} from './templates/PressKitPage';
import {ProjectPage} from 'src/templates/ProjectPage';
import {NDAPage} from 'src/templates/NDAForm';
import {IAICPage} from './templates/IAICForm';
import { PrivacyPolicyPage } from './templates/PrivacyPolicyPage';

const OriginRoute = ({location}: RouteProps): JSX.Element => {
  return (
    <>
      <GlobalStyle/>
      <Header path={location.pathname}/>
      <ContentArea>
        <Route exact={true} path='/' render={IndexPage}/>
        <Route path='/about' render={AboutUsPage}/>
        <Switch>
          <Route exact path='/investments/past/:slug'>
            <ProjectPage past={true}/>
          </Route>
          <Route exact path='/investments/:slug'>
            <ProjectPage/>
          </Route>
          <Route exact path='/investments' render={InvestmentsPage}/>
        </Switch>
        <Route path='/portfolio' render={PortfolioPage}/>
        <Route path='/our-firm' render={OurFirmPage}/>
        <Route exact={true} path='/news' render={NewsPage}/>
        <Route path='/news/:title' render={GhostRead}/>
        <Route path='/business' render={BusinessPage}/>
        <Route path='/our-team' render={OurTeamPage}/>
        <Route path='/presskit' render={PressKitPage}/>
        <Route path='/contact' render={(): JSX.Element => <ContactPage/>}/>
        <Route path='/privacy-policy' render={() => <PrivacyPolicyPage/>}/>
        <Route path='/nda' render={() => <NDAPage/>} />
        <Route path='/iaic/:formkey?' render={() => <IAICPage/>} />
      </ContentArea>
    </>
  );
};

const App = (): JSX.Element => (
  <>
    <ThemeProvider theme={theme}>
      <Router basename={BASE_PATH}>
        <Route component={OriginRoute}/>
      </Router>
    </ThemeProvider>
  </>
);

export default hot(module)(App);
