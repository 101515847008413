import * as React from 'react';
import {Img} from 'src/components/Img';
import {PageImage} from 'src/components/PageImage';
import {
  LeadParagraphContainer,
  LeadParagraphHeading,
  LeadParagraph,
} from 'src/components/LeadParagraph';

import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  LeadAndPageContainer,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {Highlight} from 'src/components/Highlight';
import styled from 'src/theme';
import {Footer} from './Footer';

const MissionAndValuesSection = styled(PageSection)`
  background-color: #646464;
  color: #fff;
  padding: 2.0rem 0.5rem 2.0rem 0.5rem;
`;

const MissionAndValuesHeader = styled(PageSectionHeader)`
  margin-bottom: 1.5rem;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const ImgLabel = styled.span`
  ${(props): string => props.theme.smallFont}
  text-align: center;
`;

const StaffRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  max-width: 1000px;
  width: 100%;
  line-height: 1.5rem;
  margin: auto;
  margin-top: 1.5rem;
`;

const Principal = styled.div`
  flex: 0 0 25%;
  text-align: center;
`;

const Manager = styled.div`
  flex: 0 0 25%;
  text-align: center;
  margin-top: 1.0rem;
`;

export const PrivacyPolicyPage = (): JSX.Element => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader marginBottom='1.5rem'>
          Privacy Policy
        </PageSectionHeader>
        <PageSectionParagraph>
          NHK Capital Partners, LLC and its affiliate companies (collectively, “<b>Company</b>”, “<b>Our</b>”, “<b>Us</b>” or “<b>We</b>”) value you as our client and recognize that the privacy of your personal information is important to you.  We want you to be familiar with how we collect, use, maintain, protect, and disclose personal information and data.
        </PageSectionParagraph>
        <PageSectionParagraph>
          This Privacy Policy (the “<b>Policy</b>”) describes the privacy practices of the Company for data that we collect:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Through websites operated by us from which you may be accessing this Policy, including <a href='https://www.nhkcapitalpartners.com'>https://www.nhkcapitalpartners.com</a> and other websites owned or controlled by the Company and its affiliates (collectively, the “<b>Websites</b>”);</li>
            <li>Through our social media pages that we control from which you may be accessing this Policy (collectively, our “<b>Social Media Pages</b>”);</li>
            <li>Through HTML-formatted email messages that we send you that link to this Policy and through your communications with us; and,</li>
            <li>When you exchange information with us through a secure cloud-based document service such as DropBox or Sharefile; and</li>
            <li>When you visit our facilities, speak with one of our case managers via telephone, chat applications, email, or through any other offline interactions.</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Collectively, we refer to the Websites and our Social Media Pages, as the “<b>Online Services</b>” and, together with any other offline channels described above, the “<b>Services.</b>” By using the Services, you agree to the terms and conditions of this Policy.
        </PageSectionParagraph>
        <PageSectionParagraph>
          By visiting <a href='https://www.nhkcapitalpartners.com'>https://www.nhkcapitalpartners.com</a> and any related websites you are accepting the practices described in this Policy. 
        </PageSectionParagraph>
        <PageSectionParagraph>
          Please read this Policy carefully to understand our policies and practices regarding your personal information and how we will treat it.  If you do not agree with our policies and practices, you should not use our Services. By accessing or using our Services, you agree to this Policy. This Policy may change from time to time.  Your continued use of our Services after we make changes is deemed to be acceptance of those changes, so please check the Policy periodically for updates. 
        </PageSectionParagraph>
        <PageSectionParagraph>
          Please note that his Policy does not apply to information collected by us through any other means, in accordance with applicable law, including on any other website operated by any third party; or any third party, including through any application or content (including advertising) that may link to or be accessible from or through the Website.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>Children Under the Age of 13</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Our Services are not intended for children under 13 years of age. No one under age 13 may provide any personal information to the Online Services. We do not knowingly collect personal information from children under 13 without their parent’s consent. If you are under 13 and your parent or custodian has not consented, do not use or provide any information on through our Online Services or through any of their features, register on the Online Services, use any of the interactive or public comment features of the Online Services, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental or custodial consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at: 
        </PageSectionParagraph>
        <PageSectionParagraph><br/>
          NHK Capital Partners, LLC<br/>
          5910 N Central Expy, Suite 1000<br/>
          Dallas, TX 75206<br/>
          info@nhkcapitalpartners.com<br/>
          469.638.8801
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>PERSONAL INFORMATION WE GATHER</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Our goal in collecting visitor information online or in person is to provide the best experience possible, through delivering relevant information that meets user needs and/or to allow us to provide the Services.  We have collected one or more of the following types of personal information within the last twelve (12) months:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Name</li>
            <li>Address</li>
            <li>City, State, Zip</li>
            <li>Phone number</li>
            <li>Date of Birth</li>
            <li>Social Security Number/Tax Identification Number</li>
            <li>Information on your family, profession, educational background, and other personal information</li>
            <li>Email</li>
            <li>Information related to your investment with us </li>
            <li>Banking, payment, and investment information necessary for us to perform any services for you</li>
            <li>Information related to verification of accredited investor status</li>
            <li>Information about your computer such as Internet Protocol (IP) Address, browser type, or operating system</li>
            <li>User Agent</li>
            <li>Cookies</li>
            <li>Online Services usage information, including third party tracking codes, which are stored and used by non-NHK affiliated third party websites</li>
            <li>Logs files containing pages accessed, browser type, device type, error messages</li>
            <li>Analytics tools of third party companies that are used and stored by such third party companies</li>
            <li>Logs containing summaries of interactions with us, including phone calls</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          We may receive and store any information you enter through our Online Services or give us in any other way, including in person or telephonically. You can choose not to provide certain information, but, by choosing to not provide such information, you might not be able to take advantage of certain features on the Websites or potentially any of the Services or any other services we may otherwise provide.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>INFORMATION WE SHARE WITH OTHERS</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Any personal information you provide to us through our Online Services, such as when you register for emails, make an inquiry, send a message, or submit a request for information is maintained in private files on a secure web server of the Company. NHK shares this information only with affiliated companies in order to help us provide additional and better services to you. These affiliates are required to maintain the privacy of your information.
        </PageSectionParagraph>
        <PageSectionParagraph>
          We used or disclosed one or more types of the personal information we collect for one or more of the following purposes, as applicable, within the last twelve (12) months:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>To contractors, service providers, and other third parties we use to support our business and who are either bound by contractual obligations to keep such personal information confidential or are subject to applicable law, and/or will use it only for the purposes for which we disclose it to them.</li>
            <li>To fulfill or meet the reason you provided the information, including supporting your contractual relationship with us. For example, if you share your name and contact information to ask a question, we will use that personal information to respond to your inquiry.</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
            <li>To provide, support, personalize, and develop our Services.</li>
            <li>To create, support, maintain, and secure your investment with us.</li>
            <li>To process your requests, purchases, transactions, and payments and prevent transactional fraud. For example, if you subscribe to a limited partnership sponsored by the Company, we will share your address with a shipping company if we send out documentation related to your subscription.</li>
            <li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
            <li>To personalize your experience on using our Online Services.</li>
            <li>To help maintain the safety, security, and integrity of our Services, databases, and other technology assets, and business. This includes exchanging information with other non-affiliated companies and organizations for fraud protection and credit risk reduction.</li>
            <li>For testing, research, analysis, and product development, including to develop and improve our Services.</li>
            <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
            <li>To allow you to participate in interactive features on our Online Services, if any.</li>
            <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</li>
            <li>For any other purpose with your consent.</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
        We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
        </PageSectionParagraph>
        <PageSectionParagraph>
        As we continue to develop the Company, we might sell, buy, or create new businesses. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in this Policy. Also, in the event Company and its affiliates are acquired, customer information of the acquired entity will of course be one of the transferred assets.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>We do not sell, rent, share, or otherwise disclose personally identifiable information from clients for commercial purposes in violation of the commitments set forth in this Policy. </b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>REGISTERING FOR EMAIL UPDATES</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Your email address will not be sold or shared with non-affiliated companies. By signing up for communication with us, we will use your email to communicate with you regarding our Services.  Should you ever wish to remove your name from our email list, unless you have engaged us for our Services, simply advise the Company that you no longer would like to receive emails from us.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>LINKS TO OTHER WEBSITES</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          When using links found on our site to other websites that are not affiliated with us, please be aware those sites have their own privacy policies, which may be different from our own. Also, the Company is not responsible for services or products that are offered on these websites.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>DATA COLLECTION TECHNOLOGIES AND USE OF COOKIES</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          As you navigate through and interact with our Online Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns such as your location and IP address.
        </PageSectionParagraph>
        <PageSectionParagraph>
          As you navigate through and interact with our Online Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns such as your location and IP address.
        </PageSectionParagraph>
        <PageSectionParagraph>
          The Websites use or may use gifs, cookies, and reverse IP technology to collect non-personal information regarding use of our Websites including, but not limited to: pages read, content downloaded, product interest, demographics, interests, time spent, frequency of visits, the service provider, operating system, and IP address. Cookies are a small numeric identifier known as a Unique Visitor Cookie which differentiates one user from another. Users are identified only by a randomly assigned number, not by personally identifiable information.
        </PageSectionParagraph>
        <PageSectionParagraph>
          If you do not wish to have a cookie on your system, you can modify your browser settings to disable cookies.  However, please be advised the not using cookies may affect functionality of the Websites and the Websites may not work properly.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>SECURITY</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Keeping your personal information secure is important to us, which is why we use a secure server and Secure Socket Layer (SSL) technology.
        </PageSectionParagraph>
        <PageSectionParagraph>
          When using secure pages of our Website, your computer will automatically begin to communicate with our server in secure mode. SSL technology encrypts your personal information on these secure pages, to protect it from being read by others during online transmittal.  When applicable, we highly recommend that you create a secure password with at least 8 characters, mixed with upper and lower case letters, numbers, and special characters. And that you change your password at least every 6 months.  
        </PageSectionParagraph>
        <PageSectionParagraph>
          Note use of any Social Media Pages that are not wholly owned and controlled by the Company (i.e. such as the Company’s TwitterTM feed or FacebookTM page) to provide personal information to Company, may not use SSL technology.  You are strongly encouraged not to share any personal information via the Social Media Pages if you would like such information to be subject to the additional encryption security measures of SSL technology. 
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>POLICY CHANGES</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          From time to time, the Company may update this Policy. Please be aware that whenever a change is made, the Policy as updated will be posted through one or more of our Online Services.
        </PageSectionParagraph>
        <PageSectionParagraph>
          If you have any questions or concerns regarding this Policy, please contact us at the address below:
        </PageSectionParagraph>
        <PageSectionParagraph><br/>
          NHK Capital Partners, LLC<br/>
          5910 N Central Expy, Suite 1000<br/>
          Dallas, TX 75206<br/>
          info@nhkcapitalpartners.com<br/>
          469.638.8801<br/>
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>NOTICE TO CALIFORNIA RESIDENTS</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          If you are a resident of the State of California, under the California Consumer Privacy Act you have the right to request that we disclose certain information about our collection and use of your personal information over the past 12 months.  Once we receive and confirm your verifiable consumer request, we will disclose to you:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>The categories of personal information we collected about you.</li>
            <li>The categories of sources for the personal information we collected about you.</li>
            <li>Our business or commercial purpose for collecting that personal information.</li>
            <li>The categories of third parties with whom we share that personal information.</li>
            <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
            <li>If we disclosed your personal information for a business purpose, a list disclosing disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained. </li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
        </PageSectionParagraph>
        <PageSectionParagraph>
          You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
            <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
            <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.</li>
            <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
            <li>Comply with a legal obligation.</li>
            <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Emailing us at: info@nhkcapitalpartners.com</li>
            <li>Calling us at: 469.638.8801</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Copy and/or scan of applicable photo identification;</li>
            <li>Confirmation of address, phone number, and birthdate;</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Additionally, the verifiable consumer request must describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.  We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.
        </PageSectionParagraph>
        <PageSectionParagraph>
          We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.
        </PageSectionParagraph>
        <PageSectionParagraph>
          Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
        </PageSectionParagraph>
        <PageSectionParagraph>
          We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <b>Other State Privacy Rights</b>
        </PageSectionParagraph>
        <PageSectionParagraph>
          If you are a Nevada, Colorado, Virginia, or Utah resident, your state’s laws may provide you with additional rights regarding our use of your personal information. 
        </PageSectionParagraph>
        <PageSectionParagraph>
          Colorado, Virginia and Utah each provide their state residents with rights to:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Confirm whether we process their personal information.</li>
            <li>Access and delete certain personal information.</li>
            <li>Data portability.</li>
            <li>Opt-out of personal data processing for targeted advertising and sales.</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          Colorado and Virginia also provide their state residents with rights to:
        </PageSectionParagraph>
        <PageSectionParagraph>
          <ul>
            <li>Correct inaccuracies in their personal information, taking into account the information’s nature processing purpose.</li>
            <li>Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.</li>
          </ul>
        </PageSectionParagraph>
        <PageSectionParagraph>
          To exercise any of these rights please either email us at: info@nhkcapitalpartners.com or call us at: 469.638.8801.  To appeal a decision regarding a consumer rights request, such submissions should be provided in writing via the email above, which shall be reviewed by the Company and determined what portion, if any, such rights will be sustained in our reasonable discretion.  
        </PageSectionParagraph>
        <PageSectionParagraph>
          Furthermore, Nevada provides its residents with a limited right to opt-out of certain personal information sales.  However, please know we do not currently sell data triggering that statute’s opt-out requirements.
        </PageSectionParagraph>
      </PageSection>
      <PageSection>
        <Footer/>
      </PageSection>
    </PageBackground>
  </>
);
