import * as React from 'react';
import styled from 'src/theme';

const PageContentContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const MainContentContainer = styled.div`
  position: absolute;
  max-width: 2000px;
  width: 100%;

  @media screen and (min-width: 420px) {

  }
`;

interface IContentAreaPropTypes {
  children: any;
}

export const ContentArea: React.FC = ({children}: IContentAreaPropTypes): JSX.Element => (
  <PageContentContainer>
    <MainContentContainer>
      {children}
    </MainContentContainer>
  </PageContentContainer>
);
