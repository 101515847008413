import * as React from 'react';

import {DotTimeline, TimelineDate, TimelineElement, TimelineLabel} from 'src/components/DotTimeline';
import {
  FullscreenLandingHeaderAddress, FullscreenLandingHeaderContents,
  FullscreenLandingHeaderFirm, FullscreenLandingHeaderLocation, FullscreenLandingHeaderTitle,
} from 'src/components/FullscreenLandingHeader';
import {Column, Row} from 'src/components/Grid';
import {UnstyledList} from 'src/components/List';
import {
  PageBackground,
  PageSection,
  PageSectionContent,
  PageSectionHeader,
  PageSectionParagraph,
  PageSectionSideImage,
  PageSectionSubHeader,
} from 'src/components/PageSection';
import {IProject} from 'src/lib/IProject';
import {Footer} from './Footer';

interface IProjectProps {
  project: IProject;
}

export const Project = ({project}: IProjectProps) => (
  <>
    <FullscreenLandingHeaderContents>
      <FullscreenLandingHeaderTitle>
        {project.name}
        <FullscreenLandingHeaderLocation>
          {project.locationLine1}
        </FullscreenLandingHeaderLocation>
        <FullscreenLandingHeaderAddress>
          {project.locationLine2}
        </FullscreenLandingHeaderAddress>
        <div>
          <FullscreenLandingHeaderFirm src='corporate/stillwater-logo.png'/>
          <FullscreenLandingHeaderFirm src='corporate/hlr-logo.jpg'/>
        </div>
      </FullscreenLandingHeaderTitle>
    </FullscreenLandingHeaderContents>
    <PageBackground>
      {project.content()}
    </PageBackground>
    <strong>Disclaimer: Forward-Looking Statements:</strong>
    Any statements contained in this document regarding us,
    our expectations, beliefs, plans, objectives, assumptions
    or future events or performance are not historical facts
    and are forward-looking statements. Readers are cautioned
    that these forward-looking statements involve uncertainties
    and risks that could cause actual performance and results of
    operations to differ materially from those anticipated.
    The forward-looking statements contained herein represent
    our judgement as of the date of publication of this release
    and we caution you not to place undue reliance on such statements.
    Our company, our managements, and our affiliates assume no
    obligation to update ant forward-looking statements to reflect
    events after this initial publication of this document or to
    reflect the occurance of subsequent events.
    <Footer/>
  </>
);
