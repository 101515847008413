import {createGlobalStyle} from './theme';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: white;
    margin: 0;
    font-size: 1.25rem;
    line-height: 2rem;
    color: ${({theme}): string => theme.primaryDarkFontColor};
    ${({theme}): string => theme.smallFont};
  }
  h1,h2,h3,h4,h5,h6,p,figure {
    margin: 0;
  }
`;
