import styled from 'src/theme';

import {Img} from 'src/components/Img';

import FullscreenLandingHeaderImage from 'src/images/main-bk.jpg';
import {getImage} from 'src/lib/getImage';

export const FullscreenLandingHeader = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image: url('${getImage(FullscreenLandingHeaderImage)}');
  background-size: cover;

  display: flex;
  flex-direction: row;
`;

const headerFontSize = 4;

export const FullscreenLandingHeaderContents = styled.div`
  height: 80vh;
`;

export const FullscreenLandingHeaderTitle = styled.h1`
  ${({theme}) => theme.headerFontHeavy}
  font-size: ${headerFontSize}rem;
  line-height: ${headerFontSize}rem;
  text-shadow: 2px 2px 6px #000000FF;
  color: white;
  text-align: center;

  position: absolute;
  top: calc(40vh - ${headerFontSize / 2}rem);
  left: 0;
  right: 0;
  margin: auto;
`;

export const FullscreenLandingHeaderLocation = styled.div`
  ${({theme}) => theme.headerFont}
  font-size: ${headerFontSize * 0.7}rem;
  line-height: ${headerFontSize}rem;
  text-shadow: 2px 2px 6px #000000FF;
  color: white;
  text-align: center;
`;

export const FullscreenLandingHeaderAddress = styled.div`
  ${({theme}) => theme.smallFont}
  font-size: ${headerFontSize * 0.35}rem;
  line-height: ${headerFontSize}rem;
  text-shadow: 2px 2px 6px #000000FF;
  color: white;
  text-align: center;
`;

export const FullscreenLandingHeaderParagraph = styled.div`
  ${({theme}) => theme.smallFont}
  font-size: ${headerFontSize * 0.35}rem;
  line-height: ${headerFontSize * 0.5}rem;
  text-shadow: 2px 2px 6px #000000FF;
  color: white;
  text-align: center;
`;

export const FullscreenLandingHeaderFirm = styled(Img)`
  height: 50px;
  margin: 5px;
`;
