import * as React from 'react';

import {Link} from 'src/components/Link';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  PageSectionParagraph,
} from 'src/components/PageSection';
import styled, {keyframes} from 'src/theme';
import {Footer} from './Footer';

const PageMinorHeaderOurFirm = styled.h3`
  font-size: 1.75rem;
  font-weight: 700;
  margin-left: 3rem;
  line-height: 3.5rem;
`;

const flipDown = keyframes`
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  } 5% {
    opacity: 1;
  } 80% {
    transform: rotateX(8deg);
  } 83% {
    transform: rotateX(6deg);
  } 92% {
    transform: rotateX(-3deg);
  } 100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
`;

const OurFirmAccordianSection = styled.li`
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  padding-top: 18px;
  border-top: 1px dotted rgba(150, 150, 150, 1);

  overflow: hidden;

  animation: ${flipDown} 0.5s ease both;

  &:last-of-type {
    padding-bottom: 0;
  }

  i {
    position: absolute;
    transform: translate( -8px , 0 );
    margin-top: 16px;
    right: 0;

    &:before , &:after {
      content: "";
      transition: all 0.25s ease-in-out;
      position: absolute;
      background-color: rgba(255, 10, 10, 1);
      width: 3px;
      height: 9px;
    }

    &:before {
      transform: translate( -2px , 0 ) rotate( 45deg );
    }

    &:after {
      transform: translate( 2px , 0 ) rotate( -45deg );
    }
  }
  input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;

    &:checked {
      &~p {
        margin-top: 0;
        max-height: 0;
        opacity: 0;
        transform: translate( 0 , 50% );
      }
      &~i {
        &:before {
          transform: translate( 2px , 0 ) rotate( 45deg )
        }

        &:after {
          transform: translate( -2px , 0 ) rotate( -45deg )
        }
      }
    }
  }
`;

const OurFirmAccordianContainer = styled.ul`
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;

  p {
    padding: 0;
    position: relative;
    overflow: hidden;
    opacity: 1;
    max-height: 800px;
    transition: all 0.25s ease-in-out;
    transform: translate( 0 , 0 );
    margin-top: 14px;
    z-index: 2;
  }
`;

export const OurFirmPage = () => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader>Our Firm</PageSectionHeader>
        <PageSectionParagraph>
          NHK Capital Partners, a Hogan Company, focuses on value-driven investment
          opportunities in the U.S. alternative investment sector. Our separate
          investment structures have been created to help our investors meet a
          range of objectives, whether it means capital appreciation or creating
          a steady stream of ordinary income.
        </PageSectionParagraph>
        <PageSectionParagraph>
          Our investment philosophy is enhanced further by the trust from our
          network of investors, whose trust was created through the “take care
          of your client” mentality of the Hogan Companies, and credibility of
          our exceptional development partners. NHK is positioned to create a
          diversified, yet simple investment experience for our investors. We
          focus on private equity and private lending as our two key investment
          vehicles that create investment opportunities for our global
          investors. Our experienced and dedicated staff has the insight of
          working with over 5,000 high net-worth clients from nearly 100
          countries. Their ability to work throughout the world, go the extra
          mile and share their knowledge base in working with all of our
          client’s needs, each with distinctive backgrounds, is unmatched
          and works to provide a concierge investment experience.
        </PageSectionParagraph>
        <br/>
        <OurFirmAccordianContainer>
          <OurFirmAccordianSection>
            <input type='checkbox' defaultChecked={true}/>
            <i/>
            <PageMinorHeaderOurFirm>The Birth of NHK Capital Partners</PageMinorHeaderOurFirm>
            <PageSectionParagraph>
              NHK Capital Partners was founded by the Hogan Family, who also operate CMB Regional Centers.
              CMB, as a pioneer and leader in the EB-5 industry, helps facilitate individuals and families
              immigration to the United States through the EB-5 investment visa program.  Having placed
              their trust in CMB, CMB’s EB-5 investors consistently share their desire for CMB to offer
              traditional, non-EB-5 focused investment opportunities. The CMB principals, who are driven
              by the “take care of your clients” mentality, realized they could further take care of their
              clients by creating a new investment platform, not only for EB-5 investors, but also their
              friends, family, and any other person who may be looking for a dependable place to make an
              investment.
            </PageSectionParagraph>
          </OurFirmAccordianSection>
          <OurFirmAccordianSection>
            <input type='checkbox' defaultChecked={true}/>
            <i/>
            <PageMinorHeaderOurFirm>
              The Legacy of the Hogan Companies
            </PageMinorHeaderOurFirm>
            <PageSectionParagraph>
              The Hogan Companies began with Patrick and Joan Hogan. Patrick, with the help and
              support of Joan, began his career as an entrepreneur operating gas stations/car
              washes that he expanded into a chain of award-winning Shell Oil service stations.
              Joan has always been a fixture within the Hogan Companies by providing a solid
              foundation for growth and ensuring the guiding principles of the Hogan family
              companies are followed.  Next, Patrick built a “Top 100 in the Nation” chain
              of video stores, several one-hour photo locations and a successful event
              photography business. In fact, Billboard Magazine once rated his video chain
              as “The Most Exciting Video Store in the Nation.” These multi-faceted operations
              won numerous marketing and printing awards for their work and employed over 300
              people during the 1990’s.
            </PageSectionParagraph>
            <PageSectionParagraph>
              In 1993, Patrick’s entrepreneurial focus shifted to
              producing firearms-related books and auction catalogs. The following year, he
              opened Rock Island Auction Company, which specializes in the auction of antique
              and collectable firearms. During 1994, Mr. Hogan also began his exploration of
              the EB-5 program. Eventually, Patrick divested his other companies and
              concurrently grew Rock Island Auction Company and CMB Regional Centers.
            </PageSectionParagraph>
            <PageSectionParagraph>
              By taking care of its clients, Rock Island Auction Company has become the #1
              auction company in the world for antique and collectable firearms, employing
              more than 100 people with annual revenues in 2018 exceeding $75 million. CMB
              Regional Centers, meanwhile, has grown to be one of the most successful and
              trustworthy EB-5 regional center groups in existence. CMB has successfully
              underwritten, placed and managed over $2.9 billion in EB-5 investment capital
              across more than 60 EB-5 investment partnerships.
            </PageSectionParagraph>
            <PageSectionParagraph>
              Joining Patrick and Joan in one of their entrepreneurial ventures is Mr. Kevin
              Hogan.  Kevin has been involved with Rock Island Auction Company since a child,
              including writing catalog descriptions and acting as one of the auction
              company’s renowned auctioneers.  Commencing in January 2016, Kevin was
              appointed as the President of Rock Island Auction Company, and under his
              helm, Kevin oversaw the single highest auction total in company history.
              Kevin also called, as the auctioneer, the world record for the most
              valuable single firearm ever sold at auction.
            </PageSectionParagraph>
            <PageSectionParagraph>
              Ms. Noreen Hogan in 2014 joined the Hogan Companies. Prior to being appointed
              as the President of CMB Regional Centers in 2018, Ms. Noreen Hogan utilized
              her master’s degree in economics and prior business experience in the
              positions of Economist and Project Manager as well as Vice President of
              Company Operations.  In 2015, Noreen, as the Director of Worldwide Operation,
              worked for CMB Swiss Co GmbH, a Swiss company and another Hogan Company.
              As the Director of Worldwide Operations, Ms. Noreen Hogan worked to establish
              and grow CMB Swiss Co to become the exclusive sourcing company for its
              affiliated companies, CMB Regional Centers.
            </PageSectionParagraph>
            <PageSectionParagraph>
              It is from this collaborative and entrepreneurial base that the Hogan family
              has created the newest Hogan Company, NHK Capital Partners.
            </PageSectionParagraph>
          </OurFirmAccordianSection>
          <OurFirmAccordianSection>
            <input type='checkbox' defaultChecked={true}/>
            <i/>
            <PageMinorHeaderOurFirm>The NHK Difference</PageMinorHeaderOurFirm>
            <PageSectionParagraph>
              NHK Capital Partners, LLC’s affiliate, CMB Regional Centers has raised over
              $2.9 billion from foreign national investors seeking US permanent residency
              through the EB-5 program and provided funding for projects in a
              vast variety of real estate properties: logistics, residential & multi-family
              housing, hotel & hospitality, resort & amusement facilities, student housing,
              office & mixed use, solar energy, and bio refinery developments. CMB Regional
              Centers’ EB-5 Partnerships have a 100% approval rate, thus delivering on the
              number one goal of EB-5 investors, a green card for themselves and their families.
            </PageSectionParagraph>
            <PageSectionParagraph>
              NHK Capital Partners builds on this strong tradition and success, utilizing
              the client-focused mission of the Hogan Companies.  NHK focuses on creating
              investment opportunities for individuals who would like access to investment
              products typically only available to institutional investors.  Utilizing the
              synergy of the trust from our network of investors and the experience and
              success of our network of developers, NHK is uniquely positioned to offer
              you a distinct opportunity to meet your investment needs. NHK offers our
              investors the opportunity for capital gains in an
              {' '}
              <Link
                to='/business/equity-investment'
              >
                Equity Investment Partnership
              </Link>
              {' '}
              or to see a risk-adjusted steady flow of income throughout the investment
              period in a
              {' '}
              <Link
                to='/business/debt-investment'
              >
                Debt Investment Partnership
              </Link>.
            </PageSectionParagraph>
          </OurFirmAccordianSection>
        </OurFirmAccordianContainer>
      </PageSection>
    </PageBackground>
    <Footer/>
  </>
);
