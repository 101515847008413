import * as React from 'react';
import {useEffect, useState} from 'react';
import supportsWebP from 'supports-webp';
import styled from 'src/theme';

const DivWithBackgroundImage = styled.div`
  padding-top: 12rem;
  width: 100%;
  height: 10rem;
  background: url(${(props) => props.src}) no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (min-width: 420px) {
    height: 20.0rem;
    width: 100%;
    background: url(${(props) => props.src}) no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export interface IImagePropTypes {
  src: string;
  asDivWithBackground?: boolean;
  external?: boolean;
}

export interface IImageSrcInfo {
  format: string;
  height: number;
  width: number;
  name: string;
  preset: string;
  type: string;
  url: string;
}

const getTargetImage = (imageSrcInfo: IImageSrcInfo[]) => {
  let selectedSrcInfo;
  if (supportsWebP) {
    selectedSrcInfo = imageSrcInfo.filter((srcDatum: IImageSrcInfo) =>
      srcDatum.preset === 'default' && srcDatum.format === 'webp',
    )[0];
  }
  if (!selectedSrcInfo) {
    selectedSrcInfo = imageSrcInfo.filter((srcDatum: IImageSrcInfo) =>
      srcDatum.preset === 'default' && srcDatum.format !== 'webp',
    )[0];
  }
  return selectedSrcInfo;
};
const getPrefetch = (imageSrcInfo: IImageSrcInfo[]) => {
  return imageSrcInfo.filter((srcDatum: IImageSrcInfo) =>
    srcDatum.preset === 'prefetch',
  )[0];
};

export const Img = ({src, external, ...props}: IImagePropTypes) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageStartedLoading, setImageStartedLoading] = useState(false);

  let displayedImage = {
    url: src,
  };
  let targetSrc = {url: ''};
  if (!external) {
    const allSrcInfo = require(`src/images/${src}`);
    const previewSrc = getPrefetch(allSrcInfo);
    targetSrc = getTargetImage(allSrcInfo);
    displayedImage = imageLoaded ? targetSrc : previewSrc;
  }

  useEffect(() => {
    if (external) return;
    if (!imageStartedLoading) {
      setImageStartedLoading(true);
      const downloadingImage = new Image();
      downloadingImage.onload = () => {
        setImageLoaded(true);
      };
      downloadingImage.src = targetSrc.url;
    }
  }, [external, imageStartedLoading, targetSrc.url]);

  if (!props.asDivWithBackground) {
    return (
      <img src={displayedImage.url} {...props}/>
    );
  } else {
    return (
      <DivWithBackgroundImage src={displayedImage.url}/>
    );
  }
};

