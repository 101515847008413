import * as React from 'react';

import {Column, Row} from 'src/components/Grid';
import {Img} from 'src/components/Img';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  PageSectionMinorHeader,
} from 'src/components/PageSection';
import styled from 'src/theme';
import {Footer} from './Footer';

interface IStaffPhotoProps {
  imageSrc: string;
  name: string;
}

const StaffPhotoContainer = styled.div`
  max-width: 150px;
  width: 100%;
  margin: auto;
`;

const StaffPhoto = ({imageSrc, name}: IStaffPhotoProps) => {
  return (
    <Column>
      <StaffPhotoContainer>
        <h4>{name}</h4>
        <Img src={`staff/${imageSrc}.jpg`}/>
      </StaffPhotoContainer>
    </Column>
  );
};

export const OurTeamPage = () => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader>Our Team</PageSectionHeader>
        <PageSectionMinorHeader>Principals</PageSectionMinorHeader>
        <Row>
          <StaffPhoto imageSrc='nhogan' name='Noreen C. Hogan'/>
          <StaffPhoto imageSrc='khogan' name='Kevin P. Hogan'/>
        </Row>
        <br/>
        <PageSectionMinorHeader>Management Team</PageSectionMinorHeader>
        <Row>
          <StaffPhoto imageSrc='pellis' name='Pam Ellis'/>
          <StaffPhoto imageSrc='nlee' name='Neal Lee'/>
          <StaffPhoto imageSrc='mhogan' name='Matthew Hogan'/>
          <StaffPhoto imageSrc='tmoffit' name='Tara Moffit'/>
        </Row>
        <br/>
        <Row>
          <StaffPhoto imageSrc='roshan' name='Roshan Verma'/>
          <StaffPhoto imageSrc='mtemple' name='Matthew Temple'/>
          <StaffPhoto imageSrc='andrew' name='Andrew Radosevich'/>
        </Row>
      </PageSection>
    </PageBackground>
    <Footer/>
  </>
);
