import * as React from 'react';
import styled, {css} from 'src/theme';

import {IImagePropTypes, Img} from 'src/components/Img';

interface IFloatingImage {
  flip?: boolean;
}
interface IInnerImageProps extends IImagePropTypes, IFloatingImage { }

const InnerImg = styled(({flip, ...props}) => <Img {...props}/>)`
  width: 100%;
  z-index: 100;
  position: relative;
  padding: 30px;
  ${(props: IInnerImageProps) => props.flip ? css`padding-left: 0;` : css`padding-right: 0;`}
`;

export const ImgContainer = styled.div<IFloatingImage>`
  ${(props) => props.flip ? css`padding-left: 30px;` : css`padding-right: 30px;`}
  width: calc(100% - 50px);
  display: inline-block;
  z-index: 9;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background-color: #fff;
    width: calc(100% - 50px);
    height: 100%;
    top: 0;
    z-index: 1;
    color: rgba(0,0,0,.13);
    box-shadow: 0 0 30px 0;
    ${({flip}) => flip && css`right: -30px;`}
  }
`;

export const FloatingImage = (props: IInnerImageProps) => (
  <ImgContainer {...props}>
    <InnerImg src={props.src} flip={props.flip}/>
  </ImgContainer>
);
