import * as React from 'react';
import {Img} from 'src/components/Img';
import styled from 'src/theme';

export const PageImage = styled(Img)`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
`;
