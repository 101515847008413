import * as React from 'react';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
} from 'src/components/PageSection';
import styled from 'src/theme';
import {Ghost} from './Ghost';
import {Footer} from './Footer';

const HighlightBar = styled(PageSectionHeader)`
 padding: 1.0rem 0 1.0rem 0;
 background-color: ${({theme}): string => theme.highlight}
`;

export const NewsPage = (): JSX.Element => (
  <PageBackground>
    <PageSection mobileTop='6.25rem' desktopTop='7.9rem'>
      <HighlightBar>
        News
      </HighlightBar>
      <br/>
      <Ghost/>
    </PageSection>
    <PageSection mobileTop='8rem' desktopTop='8rem'>
      <Footer/>
    </PageSection>
  </PageBackground>
);
