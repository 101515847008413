import * as React from 'react';

import {Column, Row} from 'src/components/Grid';
import {Link} from 'src/components/Link';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {getImage} from 'src/lib/getImage';
import styled, {css} from 'src/theme';
import {Footer} from './Footer';

import {Route} from 'react-router';
import {IImageSrcInfo} from 'src/components/Img';
import DebtInvestmentPartnershipImage from 'src/images/business/debt-investment-partnership.jpg';
import EquityInvestmentPartnershipImage from 'src/images/business/equity-investment-partnership.jpg';

interface IBusinessSubpageLinkBoxImages {
  [path: string]: IImageSrcInfo[];
}

const BusinessSubpageLinkBoxImagesData: IBusinessSubpageLinkBoxImages = {
  'debt-investment-partnership': DebtInvestmentPartnershipImage,
  'equity-investment-partnership': EquityInvestmentPartnershipImage,
};

const MissionAndValuesHeader = styled.h3`
  font-size: 1.75rem;
  font-weight: 700;
  font-style: italic;
  margin-left: 3rem;
`;

const MissionAndValuesList = styled.ul`
  list-style-position: outside;
  margin-left: 3rem;
  li {
    max-width: 500px;
    margin-bottom: 1rem;
    line-height: 1.3rem;
  }
`;

const BusinessSubpageLinkBoxBackground = styled.div`
  ${({path}: IBusinessSubpageLinkProps) => css`
    background-image: url('${getImage(BusinessSubpageLinkBoxImagesData[path])}');
  `};
  background-size: cover;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

const BusinessSubpageLinkBox = styled(Link)`
  height: 256px;
  width: 256px;
  margin: 1em auto;
  position: relative;

  padding: 0.3em;

  border-radius: 0.3em;
  text-decoration: underline rgba(255, 255, 255, 0.5);
  overflow: hidden;

  h2 {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 0.4em;
    color: white;

    transition: transform 0.25s;
    transform: translateY(0em);
  }

  h3 {
    font-size: 1.5rem;
    text-align: center;

    transition: transform 0.33s;
    transform: translateY(12em);

    text-align: center;
    color: black;

    background-color: rgba(255, 255, 255, 0.34);
    box-shadow: 1px 1px 3px 3px rgba(255, 255, 255, 0.34);
  }

  ${BusinessSubpageLinkBoxBackground} {
    filter: brightness(50%) grayscale(33%);
    transition: filter 0.2s;
  }

  transition: box-shadow 0.2s, transform 0.2s;

  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transform: translateY(0.4em);

  &:hover,&:focus {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transform: translateY(0em);
    h3 {
      transform: translateY(2em);
    }
    h2 {
      transform: translateY(-5em);
    }
    ${BusinessSubpageLinkBoxBackground} {
      filter: brightness(95%) grayscale(50%);
    }
  }
`;

interface IBusinessSubpageLinkProps {
  path: string;
  label: string;
  name: string;
}

const BusinessSubpageLink = ({path, label, name}: IBusinessSubpageLinkProps) => {
  return (
    <Column grow={100}>
      <BusinessSubpageLinkBox path={path} to={`/business/${path}`}>
        <BusinessSubpageLinkBoxBackground path={path}/>
        <h2>{label}</h2>
        <h3>{name}</h3>
      </BusinessSubpageLinkBox>
    </Column>
  );
};

const WhatAreYouLookingForHeader = styled.h3`
  font-size: 1.3rem;
  text-align: center;
  margin-top: 1rem;
`;

const WhatAreYouLookingForOR = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
`;

const MainBusinessPage = () => {
  return (
    <>
      <PageBackground>
        <PageSection>
          <PageSectionHeader>Asset Management</PageSectionHeader>
          <p>
            NHK Capital Partners presents its clients investment opportunities
            in commercial real estate that are otherwise traditionally reserved
            for institutional investors. Our offerings are designated and
            structured with the needs of our investors in mind, whether the
            objective is to maximize one’s capital gains, or to create a
            risk-adjusted steady flow of income throughout the investment
            period.
          </p>
          <WhatAreYouLookingForHeader>
            What are you looking for from your investment?
          </WhatAreYouLookingForHeader>
          <Row>
            <BusinessSubpageLink
              path='debt-investment-partnership'
              label='Steady Flow of Income'
              name='Debt Investment Partnership'
            />
            <Column>
              <WhatAreYouLookingForOR>
                <div>OR</div>
              </WhatAreYouLookingForOR>
            </Column>
            <BusinessSubpageLink
              path='equity-investment-partnership'
              label='Capital Appreciation'
              name='Equity Investment Partnership'
            />
          </Row>
        </PageSection>
        <PageSection>
          <PageSectionHeader>Missions &amp; Values</PageSectionHeader>
          <PageSectionParagraph>
            The Hogan Companies put the client first in all that they do.
            NHK Capital Partners, LLC is no different.  NHK professionals
            work tirelessly to provide our investors with the service and
            expertise needed to meet their investment goals.  NHK utilizes
            its experience, expertise, knowledge and resources to create
            value for its investors, and to ultimately meet the goals of
            the investor by taking care of its clients.
          </PageSectionParagraph>
          <MissionAndValuesHeader>NHK Mission</MissionAndValuesHeader>
          <MissionAndValuesList>
            <li>
              Take care of the client investor, as a fiduciary, as a family company, as a tradition
            </li>
            <li>
              Create investment products typically reserved for institutional investors
            </li>
            <li>
              Continue to maintain the trust of our existing clients, and gain the trust of new investors
            </li>
            <li>
              Continue to lead the industry in transparency
            </li>
            <li>
              Contribute to worthwhile, meaningful, development projects that transform communities
            </li>
            <li>
              Build loyalty by creating investment opportunities that meets the diverse needs of
              our clients and their future financial plans
            </li>
          </MissionAndValuesList>
          <MissionAndValuesHeader>NHK Values</MissionAndValuesHeader>
          <MissionAndValuesList>
            <li>
              Trust – by taking care of the client, Hogan Companies have gained the trust of their
              clients – NHK will continue in this tradition and hold true to the value of taking care of the client
            </li>
            <li>
              Value-Driven – by utilizing experience, maintaining the highest level of due diligence
              and monitoring of the asset, and looking for diversified strategies to meet client’s
              investment goals, NHK delivers value-driven investment opportunities focused on
              realizing the desired returns of its investors
            </li>
            <li>
              Transparency – by disclosing to the client the risks, goals and expectations, the
              clients can see the strategy and trust that NHK will provide outstanding services
              and investment opportunities
            </li>
            <li>
              Integrity – by holding ourselves to the highest ethical standard and doing what is
              right when it may not always be what is easy, NHK demonstrates true professionalism
              in its approach to business
            </li>
          </MissionAndValuesList>
        </PageSection>
      </PageBackground>
      <Footer/>
    </>
  );
};

const DebtBusinessPage = () => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader>Debt Investment Partnerships</PageSectionHeader>
        <PageSectionParagraph>
          <Link to='/business'>Back to Business Page</Link>
        </PageSectionParagraph>
        <PageSectionParagraph>
          <u>Overview</u><br/>
          Our debt investment partnerships provide funding into institutional-grade commercial
          real estate properties as mezzanine loans or preferred equity. The objective of our
          debt investment partnerships is to provide a risk-adjusted investment vehicle that
          provides a modest and steady flow of income throughout the investment period. Target
          hold periods for our debt investment partnerships are typically under 5 years.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <u>Strategy – Mitigating Risk</u><br/>
          Our 21 years of experience through our affiliated EB-5 partnerships has built advantageous
          relationships and a complete team in exercising due diligence for targeted projects
          investment as a mezzanine lender.  Our trusted relationships and due diligence create an
          ability to capitalize on the debt investment structure found in mezzanine lending and
          seek to achieve steady returns for our investors.
        </PageSectionParagraph>
      </PageSection>
    </PageBackground>
    <Footer/>
  </>
);
const EquityBusinessPage = () => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader>Equity Investment Partnerships</PageSectionHeader>
        <PageSectionParagraph>
          <Link to='/business'>Back to Business Page</Link>
        </PageSectionParagraph>
        <PageSectionParagraph>
          <u>Overview</u><br/>
          Our equity investment partnerships hold equity ownership interests in the target projects.
          The objective of our equity partnership is to provide an investment vehicle that maximizes
          the return profile by seeking long-term capital appreciation. This investment strategy
          intends to provide more ambitious gains for calculated risk bearing investors; such gains
          would typically be realized at the end of the investment term. Target hold periods for
          our equity investments are anticipated to be under 5 years.
        </PageSectionParagraph>
        <PageSectionParagraph>
          <u>Strategy – Value-Driven</u><br/>
          Our due diligence on the developers and projects, combined with our knowledge of the real
          estate market, intensive work in monitoring operations of different asset types, and
          experience with a magnitude of developers gives us a decided ability in capitalizing
          on the strength of successful developers, and utilizing their expertise to help achieve
          the maximum return for our investors.
        </PageSectionParagraph>
      </PageSection>
    </PageBackground>
    <Footer/>
  </>
);

export const BusinessPage = () => {
  return (
    <>
      <Route exact={true} path='/business' render={() => <MainBusinessPage/>}/>
      <Route exact={true} path='/business/debt-investment-partnership' render={() => <DebtBusinessPage/>}/>
      <Route exact={true} path='/business/equity-investment-partnership' render={() => <EquityBusinessPage/>}/>
    </>
  );
};
