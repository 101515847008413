import supportsWebP from 'supports-webp';

import {IImageSrcInfo} from 'src/components/Img';

export const getImage = (image: IImageSrcInfo[]): string => {
  return image.find((x: IImageSrcInfo) =>
    x.preset === 'default' && (
      supportsWebP ? x.format === 'webp' : x.format !== 'webp'
    ),
  ).url;
};
