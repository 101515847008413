import * as React from 'react';

import {Column, Row} from 'src/components/Grid';
import {Highlight} from 'src/components/Highlight';
import {Img} from 'src/components/Img';
import {NHKLocationBar} from 'src/components/NHKLocationBar';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
} from 'src/components/PageSection';
import {contactPort} from 'src/lib/isLive';
import styled from 'src/theme';
import {Footer} from './Footer';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props): string => props.theme.headerFont}
  max-width: 100%;
  margin: 0 1.0rem 0 1.0rem;

  & form {
    width: 100%;

    @media screen and (min-width: 420px) {
      max-width: 600px;
    }
  }
`;

const StyledInput = styled.input`
  font-size: 1.3rem;
  width: 100%;
  margin: 0.25em 0;
  display: block;
  border: 0.1rem inset rgba(67, 70, 75);
  background: rgb(67, 70, 75);
  color: #fff;
  ${(props): string => props.theme.regularFont}
`;
StyledInput.textarea = StyledInput.withComponent('textarea');

interface IFormInputProps {
  label?: string;
  autoComplete?: string;
  textArea?: boolean;
  set?: (x: any) => void;
  value: any;
  disabled?: boolean;
}

const FormInput = ({label, autoComplete, textArea, value, set, disabled}: IFormInputProps): JSX.Element => {
  let TargetElement = StyledInput;
  let rows;

  if (textArea) {
    TargetElement = StyledInput.textarea;
    rows = 8;
  }

  const onChange = (e: any): void => {
    set(e.target.value);
  };

  return (
    <Column>
      <label>
        {label}
        <TargetElement rows={rows} autoComplete={autoComplete} value={value} onChange={onChange} disabled={disabled}/>
      </label>
    </Column>
  );
};

const StyledInputSpacer = styled.span`
  display: none;

  @media screen and (min-width: 420px) {
    display: block;
  }
`;

const ContactButton = styled.button`
  padding: 12px;
  width: 100%;
  max-width: 100%;
  background: rgba(230, 25, 25, 1);
  border-radius: 0.6em;
  border: 1px solid transparent;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 0.25em 0;
  cursor: pointer;
  color: white;

  &:disabled {
    background-color: rgba(200, 10, 10, 1);
  }
`;

const ErrorSuccessText = styled(Column)`
  ${(props): string => props.theme.regularFont}
  text-align: center;
  flex: 0 0 1;
  animation: throb 5s ease;

  @keyframes throb {
    50% {
      color: rgba(255, 25, 25, 1);
    }
  }
`;

export const ContactPage = ({noImage}: {noImage?: boolean}): JSX.Element => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');

  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const submit = async (e: Event): Promise<void> => {
    e.preventDefault();
    setSubmitting(true);

    const formResults = await (await fetch(`${API_URL}:${contactPort()}/api/contactform/submit`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        phone,
        message,
      }),
    })).json();
    if (formResults.success) {
      setSuccess(true);
      setError(null);
    } else {
      setError(formResults.message);
    }
    setSubmitting(false);
  };

  const disabled = submitting || success;

  console.log(noImage);

  return (
    <>
      <PageBackground>
        {noImage ? '' :
          <Img src={'stock/dallas.png'} asDivWithBackground={true}/>
        }
        <PageSection mobileTop='0rem' desktopTop='0rem' marginTop='2.0rem' marginBottom='3.0rem'>
          <PageSectionHeader>Contact <Highlight>Us</Highlight></PageSectionHeader>
          <br/>
          <FormContainer>
            <form>
              <Row>
                <FormInput
                  disabled={disabled}
                  set={setFirstName}
                  value={firstName}
                  label='First Name'
                  autoComplete='firstname'
                />
                <StyledInputSpacer>&nbsp;&nbsp;</StyledInputSpacer>
                <FormInput
                  disabled={disabled}
                  set={setLastName}
                  value={lastName}
                  label='Last Name'
                  autoComplete='lastnamename'
                />
              </Row>
              <Row>
                <FormInput disabled={disabled} set={setEmail} value={email} label='Email Address' autoComplete='email'/>
              </Row>
              <Row>
                <FormInput disabled={disabled} set={setPhone} value={phone} label='Phone Number' autoComplete='tel'/>
              </Row>
              <Row>
                <FormInput
                  disabled={disabled}
                  set={setMessage}
                  value={message}
                  textArea={true}
                  label='Message'
                  autoComplete='off'
                />
              </Row>
              <Row>
                <ContactButton disabled={disabled} onClick={submit}>Submit</ContactButton>
              </Row>
            </form>
          </FormContainer>
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <ErrorSuccessText>
            {error && error || ''}
            {submitting && 'Submitting your information' || ''}
            {success && 'We have recieved your message and we will respond as soon as possible. Thank you' || ''}
          </ErrorSuccessText>
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <NHKLocationBar/>
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <Footer/>
        </PageSection>
      </PageBackground>
    </>
  );
};
