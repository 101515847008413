import * as styledComponents from 'styled-components';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
IGlobalThemeInterface
>;

export interface IGlobalThemeInterface {
  headerFont: string;
  headerFontHeavy: string;
  smallFont: string;
  regularFont: string;

  leadParagraphHeadingFont: string;
  leadParagraphFont: string;

  primaryColor: string;
  primaryDarkFontColor: string;
  primaryDarkFontColorFaded: string;

  darkGold: string;
  highlight: string;

  xs: number;
  sm: number;
  md: number;
  lg: number;

  backgroundDark: string;

  [key: string]: any;
}

export const theme = {
  headerFont: 'font-family: Cinzel,serif; font-weight: 400;',
  headerFontHeavy: 'font-family: Cinzel,serif; font-weight: 700;',
  leadParagraphHeadingFont: 'font-family: Cinzel,serif; font-weight: 500;',
  leadParagraphFont: 'font-family: Lato,sans-serif; font-weight: 200;',
  smallFont: 'font-family: Lato,sans-serif; font-weight: 400;',
  regularFont: 'font-family: Lato,sans-serif; font-weight: 700;',
  primaryColor: '#e9e9eb',
  primaryDarkFontColor: '#202732',
  primaryDarkFontColorFaded: '#64686d',
  darkGold: '#fdbc36',
  highlight: '#b59342',
  xs: 520,
  sm: 768,
  md: 980,
  lg: 1200,
  backgroundDark: '#f00',
};

export default styled;
export {css, createGlobalStyle, keyframes, ThemeProvider};
