import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {Img} from 'src/components/Img';
import {Link} from 'src/components/Link';
import {Reg506c} from 'src/components/Reg506c';
import {projectsPort} from 'src/lib/isLive';
import {ContactPage} from 'src/templates/ContactPage';
import {Footer} from 'src/templates/Footer';
import styled from 'styled-components';

const HeaderImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

const Container = styled.div`
  width: 100%;
  padding: 2px;
  margin: auto;

  @media screen and (min-width: 720px) {
    width: 700px;
  }
  @media screen and (min-width: 1000px) {
    width: 968px;
  }
  @media screen and (min-width: 1200px) {
    width: 1100px;
  }

  h1 {
    text-align: center;
  }
`;

const ProjectRow = styled.div`
  display: flex;
  flex-direction: row;

  &>* {
    margin: 10px;
  }

  &>div,&>figure {
    flex: 1;
  }
  &>figure>img {
    width: 100%;
  }
  &>figure>p {
    text-align: center;
    text-color: rgba(0, 0, 0, 0.8);
  }
`;

const PartnershipFlyer = styled.a`
  z-index: 100000;
  display: block;
  background-color: #ff1a1a;
  font-size: 1rem;
  line-height: 1rem;
  padding: 10px;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  float: right;
  top: 0;
  right: 0;
  h2 {
    margin: 0;
    @media screen and (max-width: 420px) {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
`;

const CompletedSection = () => {
  return (
    <p>
      This partnership is fully subscribed.
      {' '}
      <Link
        to='/investments'
        onClick={(): void => window.scrollTo(0, 0)}
      >
        View our current offerings on the investments page
      </Link>
      , or,
      {' '}
      <Link
        to='/contact'
        onClick={(): void => window.scrollTo(0, 0)}
      >
        Contact Us
      </Link> for more information.
    </p>
  );
};

export const ProjectPage = ({past}: {past?: boolean}) => {
  console.log(past);
  const match = useRouteMatch<{slug: string}>(`/investments/${past && 'past/' || ''}:slug`);
  const [currentProject, setCurrentProject] = React.useState(null);
  const [pdfButtonStyle, setPdfButtonStyle] = React.useState({});

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollTop > 512) {
      setPdfButtonStyle({
        position: 'fixed',
        top: 0,
        right: 0,
      });
    } else {
      setPdfButtonStyle({
        position: 'absolute',
        top: 0,
        right: 0,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const slug = match.params.slug;

  React.useEffect(() => {
    fetch(`${API_URL}:${projectsPort()}/api/projects/${past && 'past/' || ''}list`)
      .then((response) => response.json())
      .then(async (data) => {
        if (data.success) {
          const projectSummary = data.projects.find((x: any) => x.slug === slug);
          const project = await (await fetch(`${API_URL}:${projectsPort()}/api/projects/${past && 'past/' || ''}info/${projectSummary.id}`)).json();
          project.rows = project.rows.map((x: any) => ({
            content: (x._cmbpastproject_rows_content || x._cmbproject_rows_content).replace(/(www\.|forms\.)?cmbeb5visa.com/gm, 'forms.cmbeb5visa.com'),
            media_content: (x._cmbpastproject_rows_media_content || x._cmbproject_rows_media_content).replace(/(www\.|forms\.)?cmbeb5visa.com/gm, 'forms.cmbeb5visa.com'),
            media_content_caption: x._cmbpastproject_rows_media_content_caption || x._cmbproject_rows_media_content_caption,
            media_content_id: x._cmbpastproject_rows_media_content_id || x._cmbproject_rows_media_content_id,
            media_content_size: x._cmbpastproject_rows_media_content_size || x._cmbproject_rows_media_content_size,
          }));
          setCurrentProject(Object.assign({}, projectSummary, project));
        }
      });
  }, [slug, past]);

  if (!currentProject) {
    return null;
  }

  return (
    <Reg506c style={{marginTop: '200px'}}>
      <Img src={currentProject.headline.replace(/(www\.|forms\.)?cmbeb5visa.com/gm, 'forms.cmbeb5visa.com')} external asDivWithBackground/>
      <div style={{position: 'relative'}}>
        <PartnershipFlyer
          href={currentProject.pdf}
          target='_blank'
          rel='noopener noreferrer'
          style={pdfButtonStyle}
        >
          <h2>Click here to view full partnership flyer</h2>
        </PartnershipFlyer>
      </div>
      <Container>
        <h1>
          <br/>
          {currentProject.name}
          <br/>
        </h1>
        {past &&
          <><hr/><CompletedSection/></>
        }
        <hr/>
        {currentProject.rows.map((row: any, index: number) => (
          <ProjectRow key={index}>
            {index % 2 === 0 &&
              <div
                style={{flexGrow: 12 - row.media_content_size}}
                dangerouslySetInnerHTML={{__html: row.content}}
              /> ||
            ''}
            <figure style={{flexGrow: row.media_content_size}}>
              <img src={row.media_content}/>
              <p dangerouslySetInnerHTML={{__html: row.media_content_caption}}/>
            </figure>
            {index % 2 === 1 &&
              <div
                style={{flexGrow: 12 - row.media_content_size}}
                dangerouslySetInnerHTML={{__html: row.content}}
              /> ||
            ''}
          </ProjectRow>
        ))}
      </Container>
      {past &&
        <div>
          <Container>
            <hr/>
            <CompletedSection/>
          </Container>
          <Footer/>
        </div> ||
          <ContactPage noImage={true}/>
      }
    </Reg506c>
  );
};
