import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Highlight} from 'src/components/Highlight';
import {Img} from 'src/components/Img';
import {Link} from 'src/components/Link';
import {NHKLocationBar} from 'src/components/NHKLocationBar';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {ndaIaicPort} from 'src/lib/isLive';
import styled from 'src/theme';
import {Footer} from './Footer';

const FormContainer = styled.div`
  width: 1280px;
  margin: 0 auto;
  padding: 30px 15px;
  border-radius: 15px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    width: 600px;
  }

  background: #e8e8e8;
  box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
  max-width: 90%;

  p {
    margin-bottom: 1rem;
  }

  ol {
    li {
      margin-bottom: 1rem;
    }
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
      }
    }
  }
`;

const StyledInput = styled.input`
  font-size: 1.3rem;
  margin: 0.25em 5px;
  border: 0.1rem inset rgba(67, 70, 75);
  border-radius: 5px;
  background: #f9f9f9;
  color: black;
  ${(props): string => props.theme.regularFont}
  font-weight: 500;
`;

const BlockLabel = styled.label`
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
  input {
    display: block;
    width: 500px;
    max-width: 90%;
    line-height: 2.5rem;
  }
  margin: 1rem 0;
`;

const SigningContainer = styled.div`
  padding: 0rem 6.0rem 0rem 6.0rem;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
  width: 100%;
  max-width: 100%;
`;

const Hint = styled.span`
  font-style: italic;
  color: #606060;
`;

interface IFormInputProps {
  label?: string;
  autoComplete?: string;
  set?: (x: any) => void;
  value: any;
  disabled?: boolean;
  block?: boolean;
}

const BlockInput = ({label, autoComplete, value, set, disabled}: IFormInputProps): JSX.Element => {
  const onChange = (e: any): void => {
    set(e.target.value);
  };

  return (
    <BlockLabel>
      {label}
      <StyledInput autoComplete={autoComplete} value={value} onChange={onChange} disabled={disabled}/>
    </BlockLabel>
  );
};

const SubmitButton = styled.button`
  padding: 12px;
  width: 100%;
  max-width: 100%;
  background: rgb(198, 160, 71);
  border-radius: 0.6em;
  border: 1px solid transparent;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 0.25em 0;
  cursor: pointer;
  color: white;
  font-size: 1.3rem;

  &:disabled {
    background: rgba(134, 109, 51, 0.8);
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
    background: rgb(208, 168, 73);
  }
`;

const ErrorSuccessText = styled.div`
  ${(props): string => props.theme.regularFont}
  text-align: center;
  color: rgba(255, 25, 25, 1);
  width: 100%;
`;

const Requirements = styled.ol`
  padding: 0rem 6.0rem 0rem 6.0rem;
  list-style: lower-roman;
`;

export const IAICPage = ({noImage}: {noImage?: boolean}): JSX.Element => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(moment().format('MMMM DD, YYYY'));
  const [signature, setSignature] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [phone, setPhone] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [email, setEmail] = useState('');
  const [citizenship, setCitizenShip] = useState('');
  const [referral, setReferral] = useState('');
  const [occupation, setOccupation] = useState('');
  const [netWorthCheck, setNetWorthCheck] = useState(false);
  const [incomeCheck, setIncomeCheck] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const params = useParams();

  // use formkey to populate form data
  useEffect(() => {
    const prefillForm = async () => {
      const infoReq = await fetch(`${API_URL}:${ndaIaicPort()}/api/form/getInfo/${params.formkey}`);
      const info = await infoReq.json();
      const formData = info[0]?.formInputs || null;
      if (formData) {
        setName(formData.name);
        setTitle(formData.title);
        setAddress1(formData.address1);
        setAddress2(formData.address2);
        setAddress3(formData.address3);
        setPhone(formData.phone);
        setMobilePhone(formData.mobilePhone);
        setEmail(formData.email);
      }
    };
    if (params && params.formkey) {
      prefillForm();
    }
  }, [params]);

  const submit = async (e: Event): Promise<void> => {
    e.preventDefault();
    setSubmitting(true);

    const missingFields = [];
    !name.length && missingFields.push('Name');
    !signature.length && missingFields.push('Signature');
    !phone.length && missingFields.push('Phone Number');
    !email.length && missingFields.push('Email Address');
    !address1.length&& missingFields.push('Address');
    if (missingFields.length) {
      setError(`${missingFields.join(', ')} ${missingFields.length > 1 ? 'are' : 'is'} required`);
      setSubmitting(false);
      return;
    }
    if (name !== signature) {
      setError('Printed Name and Signature must match exactly');
      setSubmitting(false);
      return;
    }

    const formResults = await (await fetch(`${API_URL}:${ndaIaicPort()}/api/form/iaic/submit`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          name,
          signature,
          date,
          title,
          address1,
          address2,
          address3,
          phone,
          mobilePhone,
          email,
          // citizenship,
          // referral,
          // occupation,
          netWorthCheck,
          incomeCheck,
        },
      }),
    })).json();
    if (formResults.success) {
      setSuccess(true);
      setError(null);
    } else {
      setError(formResults.message);
    }
    setSubmitting(false);
  };

  const disabled = submitting || success;

  return (
    <>
      <PageBackground>
        {noImage ? '' :
          <Img src={'stock/dallas.png'} asDivWithBackground={true}/>
        }
        <PageSection mobileTop='0rem' desktopTop='0rem' marginTop='2.0rem' marginBottom='3.0rem'>
          <PageSectionHeader>NHK Capital Partners<br/>
            <Highlight>INITIAL ACCREDITED INVESTOR CERTIFICATION</Highlight>
          </PageSectionHeader>
          <br/>
          {success ?
            <PageSectionParagraph centered>
              <span>Your Initial Accredited Investor Certification has been successfully submitted.</span>
              <br/><span>Thank You.</span>
              <Link to={`/`}>
                <SubmitButton>
                  Home
                </SubmitButton>
              </Link>
            </PageSectionParagraph> :
            <FormContainer>
              <SigningContainer>
                <BlockInput disabled={disabled} set={setName} value={name} label='Name:' autoComplete='firstname'/>
                <BlockInput disabled={disabled} set={setTitle} value={title} label='Title (Mr./Ms.):' />
                <BlockInput disabled={disabled} set={setAddress1} value={address1} label='Address:' autoComplete='address1'/>
                <BlockInput disabled={disabled} set={setAddress2} value={address2} autoComplete='address2'/>
                <BlockInput disabled={disabled} set={setAddress3} value={address3}/>
                <BlockInput disabled={disabled} set={setPhone} value={phone} label='Phone:' autoComplete='tel'/>
                <BlockInput disabled={disabled} set={setMobilePhone} value={mobilePhone} label='Mobile Phone:' />
                <BlockInput disabled={disabled} set={setEmail} value={email} label='Email:' autoComplete='email'/>
                {/* <BlockInput disabled={disabled} set={setCitizenShip} value={citizenship} label='Citizenship:'/>
                <BlockInput disabled={disabled} set={setReferral} value={referral} label='Source of Referral:'/>
                <BlockInput disabled={disabled} set={setOccupation} value={occupation} label='Occupation:'/> */}
              </SigningContainer>
              <p>Rule 501 of Regulation D issued pursuant to the Securities Act of 1933, as amended, defines an
“accredited investor” as, inter alia, “any natural person whose individual net worth, or joint net
worth with that person’s spouse, exceeds $1,000,000.” In calculating net worth, a person must</p>
              <Requirements>
                <li> exclude the person’s primary residence as an asset,</li>
                <li> exclude debt secured by such
residence, up to the estimated fair market value of the residence;</li>
                <li> include the amount of
any increase on the debt secured by the primary residence incurred within 60 days prior to
the purchase of the securities (unless related to the acquisition of the primary residence); and</li>
                <li> include debt in excess of the fair market value of the primary residence.</li>
              </Requirements>
              <p>
 In addition, an
“accredited investor” includes “any natural person who had an individual income in excess
of $200,000 in each of the most recent years or joint income with that person’s spouse in
excess of $300,000 in each of those years and has a reasonable expectation of reaching the
same income level in the current year.”
              </p>
              <p>I hereby certify that I am familiar with the definition of “accredited investor” as defined in Rule
501 of Regulation D and that I meet the criteria to qualify as an accredited Investor
              <Hint>(check all that apply)</Hint>:
              </p>
              <ol>
                <p><li>
                  <label><input type='checkbox' checked={netWorthCheck} onChange={() => setNetWorthCheck(!netWorthCheck)}/>
                  I am a natural person whose individual net worth, or joint net worth with
that of my spouse, exceeds $1,000,000 calculated in accordance with the abovedescribed rules.</label>
                </li></p>
                <p><li>
                  <label><input type='checkbox' checked={incomeCheck} onChange={() => setIncomeCheck(!incomeCheck)}/>
                  I am a natural person who had individual income in excess of $200,000 in each
of the two most recent years or joint income with my spouse in excess of $300,000 in
each of those years and I have a reasonable expectation of reaching the same income
level in the current year.</label>
                </li></p>
              </ol>
              <p>I agree that this Initial Accredited Investor Certification may be electronically signed. I agree that the electronic
                 signatures appearing on this agreement are the same as handwritten signatures for the purposes of validity,
                  enforceability, and admissibility and have the same force and effect. I recognize that I have the option to opt out
                   and sign with a paper signature instead.</p>
              <SigningContainer>
                <BlockInput disabled={disabled} set={setSignature} value={signature} label='Signature of Person Named Above: '/>
                <BlockInput
                  label='Date'
                  disabled={true}
                  set={setDate}
                  value={date}
                />
              </SigningContainer>
              <ErrorSuccessText>
                {error ? error : ''}
              </ErrorSuccessText>
              <SubmitButton disabled={disabled} onClick={submit}>{submitting ? 'Please wait...' : 'Submit'}</SubmitButton>
            </FormContainer>
          }
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <NHKLocationBar/>
        </PageSection>
        <PageSection mobileTop='0rem' desktopTop='0rem'>
          <Footer/>
        </PageSection>
      </PageBackground>
    </>
  );
};
