import * as React from 'react';
import {
  PageBackground,
  PageSection,
} from 'src/components/PageSection';
import {GhostArticle} from './Ghost';
import * as PropTypes from 'prop-types';

export const GhostRead = (props: any): JSX.Element => {
  return (
    <PageBackground>
      <PageSection mobileTop='8rem' desktopTop='8rem'>
        <GhostArticle title={props.match.params.title}/>
      </PageSection>
    </PageBackground>
  );
};

GhostRead.propTypes = {
  match: PropTypes.any,
};
