import * as React from 'react';
import {FloatingImage, ImgContainer} from 'src/components/FloatingImage.tsx';
import {IContainerPropTypes} from 'src/components/Grid';
import {responsive} from 'src/lib/responsive';
import styled, {css, IGlobalThemeInterface} from 'src/theme';

import {FlattenSimpleInterpolation} from 'styled-components';

export interface IPageSectionProps extends IContainerPropTypes {
  alternate?: boolean;
  alternateDark?: boolean;
  theme?: IGlobalThemeInterface;
  mobileTop?: string;
  desktopTop?: string;
  marginTop?: string;
  marginBottom?: string;
}
export interface IPageSectionInnerProps extends IPageSectionProps {
  children: any;
}

const PageSectionContainer = styled.div`
  position: relative;
  left: 0;
  margin-top: ${(props): string => props.marginTop ? props.marginTop : '0.0rem'};
  margin-bottom: ${(props): string => props.marginBottom ? props.marginBottom : '0.0rem'};
  ${(props: IPageSectionProps): FlattenSimpleInterpolation => props.mobileTop &&
    css`
      top: ${props.mobileTop};
    ` ||
    css`
      top: 20rem;
    `
}
  @media screen and (min-width: 420px) {
    ${(props: IPageSectionProps): FlattenSimpleInterpolation => props.desktopTop &&
      css`
        top: ${props.desktopTop}
      ` ||
      css`
        top: 20rem;
      `
}
  }
  ${({alternate}: IPageSectionProps): FlattenSimpleInterpolation => alternate &&
    css`
      background-color: rgba(0, 0, 0, 0.1);
    `
}
  ${(props: IPageSectionProps): FlattenSimpleInterpolation => props.alternateDark &&
    css`
      background-color: ${props.theme.backgroundDark};
      color: rgba(255, 255, 255, 0.95);
      ${ImgContainer} {
        &:before {
          background-color: ${props.theme.backgroundDark};
          color: rgba(200, 200, 200, 0.1);
        }
      }
    `
}
`;

const PageSectionInner = styled.div`
  p {
    padding: 0rem 0.5rem 0rem 0.5rem;
    @media screen and (min-width: 420px) {
      padding: 0rem 6.0rem 0rem 6.0rem;
    }
  }
`;

export const PageSection = (props: IPageSectionInnerProps): JSX.Element => (
  <PageSectionContainer {...props}>
    <PageSectionInner {...props}/>
  </PageSectionContainer>
);

export const PageSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${responsive('sm', `
    flex-direction: row;
  `)}
`;

export const PageSectionSideImage = styled(FloatingImage)`
  flex-shrink: 1;
  width: 50%;
  align-self: center;
  min-width: 200px;
  ${responsive('sm', `
    width: 40%;
  `)}
  ${responsive('md', `
    width: 30%;
  `)}
`;

export const PageSectionHeader = styled.div`
  ${({theme}): string => theme.headerFont}
  margin-top: ${(props): string => props.marginTop ? props.marginTop: '0.0rem'};
  margin-bottom: ${(props): string => props.marginBottom ? props.marginBottom: '0.0rem'};
  font-size: 2.3rem;
  line-height: 2.6rem;
  text-align: center;
  width: 100%;
`;

export interface IPageSectionMinorHeaderProps {
  centered: boolean;
}
export const PageSectionMinorHeader = styled.div`
  ${({theme}): string => theme.headerFont}
  font-size: 1.75rem;
  line-height: 2.5rem;
  display: block;
  ${({centered}: IPageSectionMinorHeaderProps): string => centered && 'text-align: center;' || ''}
`;

export const PageSectionSubHeader = styled.div`
  ${({theme}): string => theme.headerFontThin}
  color: ${({theme}): string => theme.primaryDarkFontColorFaded};
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  width: 100%;
`;

export interface IPageSectionParagraphProps {
  centered?: boolean;
  baseline?: boolean;
  alternate?: boolean;
  stretch?: boolean;
}

export const PageSectionParagraph = styled.p`
  max-width: 800px;
  min-width: 200px;
  padding: 6px;
  margin: auto;
  margin-bottom: 1em;
  text-indent: 1em;

  ${({centered}: IPageSectionParagraphProps): FlattenSimpleInterpolation => centered && css`text-align: center;`}
  ${({baseline}: IPageSectionParagraphProps): FlattenSimpleInterpolation => baseline && css`align-self: baseline;`}
  ${({stretch}: IPageSectionParagraphProps): FlattenSimpleInterpolation => stretch && css`align-self: stretch;`}
`;

export const LeadAndPageContainer = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
max-width: 100%;
height: 100%;
`;

export const PageBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.975);
  opacity: 1;
  animation: fade 450ms ease;

  @keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`;
