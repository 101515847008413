import {Img} from 'src/components/Img';
import styled, {theme} from 'src/theme';

const headerFontSize = 4;

export const LeadParagraphContainer = styled.div`
    position: relative;
    top: ${(props) => (props.topOverride) ? props.topOverride : '20rem'};
    left: 0;
    text-align: center;
    max-width: 100%;

    @media screen and (min-width: 420px) {
        top: 12rem;
        padding-left: 3.0rem;
        padding-right: 3.0rem;
        height: 12rem;
    }
`;

export const LeadParagraphHeading = styled.div`
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-size: 1.5rem;
    ${(props) => props.theme.leadParagraphHeadingFont}

    @media screen and (min-width: 420px) {
        top: 12rem;
        font-size: 2.0rem;
    }
`;

export const LeadParagraph = styled.p`
    ${(props) => props.theme.leadParagraphFont}
    text-align: center;
    padding: 0.1rem;
    margin: auto;
    max-width: 800px;

    @media screen and (min-width: 420px) {
        text-align: center;
    }
`;

export const LeadProjectHeading = styled.h1`
    ${({theme}) => theme.headerFontHeavy}
    font-size: ${((headerFontSize - 1) > 0) ? headerFontSize - 1 : headerFontSize}rem;
    line-height: ${headerFontSize}rem;
    text-shadow: 2px 2px 6px #000000FF;
    color: white;
    text-align: center;
    margin: auto;

    @media screen and (min-width: 420px) {
        font-size: ${headerFontSize}rem;
    }
`;

export const LeadProjectLocation = styled.div`
    ${({theme}) => theme.headerFont}
    font-size: ${headerFontSize * 0.5}rem;
    line-height: ${headerFontSize}rem;
    color: black;
    text-align: center;

    @media screen and (max-width: 420) {
        color: white;
        text-shadow: 2px 2px 6px #000000FF;
    }

    @media screen and (min-width: 360px) {
        color: white;
        text-shadow: 2px 2px 6px #000000FF;
    }

    @media screen and (min-width: 375px) {
        color: white;
        text-shadow: 2px 2px 6px #000000FF;
        font-size: ${headerFontSize * 0.7}rem;
    }
`;

export const LeadProjectAddress = styled.div`
    ${({theme}) => theme.smallFont}
    font-size: ${headerFontSize * 0.35}rem;
    line-height: ${headerFontSize}rem;
    color: black;
    text-align: center;

    @media screen and (min-width: 420px) {
        color: white;
        text-shadow: 2px 2px 6px #000000FF;
    }
`;
