import * as React from 'react';
import styled from 'src/theme';
import {newsPort} from 'src/lib/isLive';
import {Link} from 'src/components/Link';
import {
  PageSection,
} from 'src/components/PageSection';
import {Footer} from './Footer';
import * as moment from 'moment';

const numArticles = 10;
const imageSize = 600;

const FeatureImage = styled.img`
 display: block;
 margin: auto;
 border-radius: 0.5rem;
 width: 100%;
 max-width: 800px;
`;

/*
const Header = styled.div`
  font-size: 30px;
  text-align: center;
  padding: 10px;
`;
*/

const Time = styled.div`
  ${({theme}): string => theme.smallFont}
  text-align: center;
`;

// box-shadow: 8px 14px 10px rgba(39,44,49,.06), -1px -1px 8px rgba(39,44,49,.03);
const Article = styled.div`
  display: block;
  max-width: 800px;
  width: 100%;
  box-shadow: 8px 14px 10px rgba(71,57.6,25.9,.5), -1px -1px 8px rgba(71,57.6,25.9,.1);
  padding-top: 1.0rem;
  margin: auto;
  margin-bottom: 5.0rem;
  border-radius: 0.5rem;
`;

const ArticleHeader = styled.h1`
  text-align: center;
  color: #000;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  ${({theme}): string => theme.headerFont}
`;

const ArticleContent = styled.div`
  font-size: 1.0rem;
  ${({theme}): string => theme.smallFont}
  line-height: 1.5em;
  max-width: 800px;
  display: block;
  margin: auto;
  margin-top: 1.5rem;

  & p {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const HeaderLink = styled(Link)`
  text-decoration: none;

  ${ArticleHeader}:hover, ${ArticleHeader}:focus {
    transition: color 0.3s ease;
    color: ${(props): string => props.theme.highlight};
  }
`;

export const Ghost = (props: any): JSX.Element => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetch(`${API_URL}:${newsPort()}/api/ghost/news`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        limit: (props.numArticles) ? props.numArticles : numArticles,
        imageSize: (props.imageSize) ? props.imageSize : imageSize,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, [props.imageSize, props.numArticles]);
  return (
    <div>
      {data.map((el, index) => (
        <Article key={'article-' + index}>
          <Link to={`/news/${el.slug}`} onClick={(): void => window.scrollTo(0, 0)}>
            <p>
              <FeatureImage src={el.feature_image.replace(/(www\.|forms\.)?cmbeb5visa.com/gm, 'forms.cmbeb5visa.com')}/>
            </p>
          </Link>
          <HeaderLink to={`/news/${el.slug}`} onClick={(): void => window.scrollTo(0, 0)}>
            <ArticleHeader>
              {el.title}
            </ArticleHeader>
          </HeaderLink>
          <ArticleContent><p>{el.excerpt.substring(0, 150)}...</p></ArticleContent>
        </Article>
      ))}
    </div>
  );
};

export const GhostArticle = (props: any): JSX.Element => {
  const [read, setRead] = React.useState(props);
  const fetchArticle = async (title: string) => {
    const apiCall = await fetch(`${API_URL}:${newsPort()}/api/ghost/read/${title}`);
    const data = await apiCall.json();
    data.feature_image = data.feature_image
      .replace(/^(.*)\/images\/(.*\/.*\..*$)/gi,
        '$1/images/size/w' + imageSize + '/$2');
    setRead(data);
  };
  React.useEffect(() => {
    fetchArticle(props.title);
  }, [props.title]);

  return (
    <React.Fragment>
      <Time>{moment(read.published_at).format('D MMMM YYYY')}</Time>
      <ArticleHeader>{read.title}</ArticleHeader>
      <FeatureImage src={read.feature_image?.replace(/(www\.|forms\.)?cmbeb5visa.com/gm, 'forms.cmbeb5visa.com')}/>
      <ArticleContent dangerouslySetInnerHTML={{__html: read.html?.replace(/(www\.|forms\.)?cmbeb5visa.com/gm, 'forms.cmbeb5visa.com')}}/>
      <PageSection mobileTop='0.5rem' desktopTop='0.5rem'>
        <Footer/>
      </PageSection>
    </React.Fragment>
  );
};
