import * as React from 'react';
import {Img} from 'src/components/Img';
import {PageImage} from 'src/components/PageImage';
import {
  LeadParagraphContainer,
  LeadParagraphHeading,
  LeadParagraph,
} from 'src/components/LeadParagraph';

import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  LeadAndPageContainer,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {Highlight} from 'src/components/Highlight';
import styled from 'src/theme';
import {Footer} from './Footer';

const MissionAndValuesSection = styled(PageSection)`
    background-color: #646464;
    color: #fff;
    padding: 2.0rem 0.5rem 2.0rem 0.5rem;
`;

const MissionAndValuesHeader = styled(PageSectionHeader)`
    margin-bottom: 1.5rem;
    color: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
`;

const ImgLabel = styled.span`
    ${(props): string => props.theme.smallFont}
    text-align: center;
`;

const StaffRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    max-width: 1000px;
    width: 100%;
    line-height: 1.5rem;
    margin: auto;
    margin-top: 1.5rem;
`;

const Principal = styled.div`
    flex: 0 0 25%;
    text-align: center;
`;

const Manager = styled.div`
    flex: 0 0 25%;
    text-align: center;
    margin-top: 1.0rem;
`;

export const AboutUsPage = (): JSX.Element => (
  <>
    <PageBackground>
      <Img src={'stock/lookupbuildings1.png'} asDivWithBackground/>
      <LeadAndPageContainer>
        <LeadParagraphContainer>
          <LeadParagraphHeading>
                Our Firm
          </LeadParagraphHeading>
          <LeadParagraph>
            NHK builds on the strong tradition and success of the client-focused mission that started nearly 30 years ago
            in the Hogan Companies. We are positioned to create a diversified, yet simple investment experience for our
            investors, with concierge level service provided to every investor. We focus on private equity and private
            lending as our two key investment vehicles that create investment opportunities for our global investors.
            Our experienced and dedicated staff has the insight of working with approximately 6,000 high net-worth
            clients from over 100 countries. Our ability to work throughout the world, go the extra mile and share our
            knowledge base, while meeting the needs of our client&apos;s, each with distinctive backgrounds, is unmatched
            and works to provide a concierge investment experience.
          </LeadParagraph>
        </LeadParagraphContainer>
        <PageSection>
          <PageSectionHeader marginTop='3.0rem'>Principals</PageSectionHeader>
          <StaffRow>
            <Principal>
              <Img src='staff/nhogan.png'/>
              <br/>
              <ImgLabel>Noreen Hogan</ImgLabel>
            </Principal>
            <Principal>
              <Img src='staff/khogan.png'/>
              <br/>
              <ImgLabel>Kevin Hogan</ImgLabel>
            </Principal>
          </StaffRow>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='3.0rem'>Management</PageSectionHeader>
          <StaffRow>
            <Manager>
              <Img src='staff/pellis.jpg'/>
              <br/>
              <ImgLabel>Pam Ellis</ImgLabel>
            </Manager>
            <Manager>
              <Img src='staff/nlee.jpg'/>
              <br/>
              <ImgLabel>Neal Lee</ImgLabel>
            </Manager>
            <Manager>
              <Img src='staff/mhogan.jpg'/>
              <br/>
              <ImgLabel>Matt Hogan</ImgLabel>
            </Manager>
            <Manager>
              <Img src='staff/tmoffit.jpg'/>
              <br/>
              <ImgLabel>Tara Moffit</ImgLabel>
            </Manager>
          </StaffRow>
          <StaffRow>
            <Manager>
              <Img src='staff/roshan.jpg'/>
              <br/>
              <ImgLabel>Roshan Verma</ImgLabel>
            </Manager>
            <Manager>
              <Img src='staff/mtemple.jpg'/>
              <br/>
              <ImgLabel>Matthew Temple</ImgLabel>
            </Manager>
          </StaffRow>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='3.0rem'>Investor Relations</PageSectionHeader>
          <StaffRow>
            <Manager>
              <Img src='staff/kenny.jpg'/>
              <br/>
              <ImgLabel>Kenny Peng</ImgLabel>
            </Manager>
            <Manager>
              <Img src='staff/vitaly.jpg'/>
              <br/>
              <ImgLabel>Vitaly Modlo</ImgLabel>
            </Manager>
            <Manager>
              <Img src='staff/mousse.jpg'/>
              <br/>
              <ImgLabel>Moussa Ndiaye</ImgLabel>
            </Manager>
          </StaffRow>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='3.0rem' marginBottom='1.5rem'>
            Mission &amp; Values
          </PageSectionHeader>
          <PageSectionParagraph>
            At NHK Capital Partners, we put our clients’ goals first when structuring high quality
            investment opportunities. Our experienced staff of professionals work tirelessly to
            provide our investors concierge level service and expertise needed to understand our
            investor’s goals and investment needs. We believe the experience we personally provide
            starting at the investor level experience, to capitalizing on the resources acquired in
            our many years of creating successful partnerships in the EB-5 investor visa program,
            provides distinctly different and unmatched value for our investors.
          </PageSectionParagraph>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='3.0rem' marginBottom='1.5rem'>
              The <Highlight>Hogan</Highlight> Companies
          </PageSectionHeader>
          <PageSectionParagraph>
            NHK Capital Partners writes a new chapter in the successful 30 year history of the Hogan Companies, which also
            includes industry leaders <a href='https://cmbeb5visa.com'>CMB Regional Centers</a> and{' '}
            <a href='https://www.rockislandauction.com'>Rock Island Auction Company</a> (RIAC).
          </PageSectionParagraph>
          <br/>
          <PageSectionParagraph>
            CMB Regional Centers is one of the most successful and trustworthy regional centers in the EB-5 investor visa program,
            with a track record of working with nearly 6,000 high net worth individuals. CMB has successfully underwritten, placed,
            and managed over $3.1 billion in EB-5 investment capital in more than 70 EB-5 investment partnerships.
          </PageSectionParagraph>
          <br/>
          <PageSectionParagraph>
            Rock Island Auction Company is the #1 auction company in the world for antique and collectable firearms with annual
            revenues in 2021 exceeding $100 million. RIAC employs more than 100 people and hosts over a dozen auctions each year,
            which have brought in several very notable world record sales.
          </PageSectionParagraph>
          <br/>
          <PageSectionParagraph>
            <PageImage src='staff/TheHoganFamily.png'/>
          </PageSectionParagraph>
        </PageSection>
        <br/>
        <MissionAndValuesSection>
          <MissionAndValuesHeader>
            Mission &amp; <Highlight>Values</Highlight>
          </MissionAndValuesHeader>
          <PageSectionParagraph>
            At the Hogan Companies we put the client first in everything we do. NHK Capital Partners is no different. Our
            professionals work tirelessly to provide our investors with the service and expertise needed to meet their
            investment goals. We utilize our experience, expertise, knowledge and resources to create value for our
            investors, and to ultimately meet the goals of the of the investor by taking care of our clients.
          </PageSectionParagraph>
        </MissionAndValuesSection>
        <br/>
        <PageSection>
          <Footer/>
        </PageSection>
      </LeadAndPageContainer>
    </PageBackground>
  </>
);
