import * as React from 'react';
import {Img} from 'src/components/Img';
import {PageImage} from 'src/components/PageImage';

import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  LeadAndPageContainer,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {Highlight} from 'src/components/Highlight';
import {NHKLocationBar} from 'src/components/NHKLocationBar';
import styled from 'src/theme';
import {Footer} from './Footer';
import {Link} from 'src/components/Link';

const OverlayArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0rem 2.5rem 3.0rem 2.5rem;
  max-width: 100%;
  text-align: center;
  color: #fff;
  height: 12rem;
  
  ${(props): string => props.theme.headerFontHeavy}
  @media screen and (min-width: 420px) {
    height: 20rem;
  }
`;

const OverlayWords = styled.span`
  margin-top: 26rem;
  width: 100%;
  flex-basis: 20%;
  flex-grow: 1;
  font-family: Cinzel,serif;
  font-weight: 600;
  font-size: 1.0rem;
  align-self: center;

  @media screen and (min-width: 420px) {
    font-size: 2.0rem;
    margin-top: 36rem;
  }
`;

const QuoteContainer = styled.div`
  position: relative;
  height: auto;
`;

const PatQuote = styled.div`
  ${(props): string => (props.theme.headerFont)}
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 3;

  @media screen and (min-width: 420px) {
    font-size: 2.0rem;
  }
`;

export const IndexPage = (): JSX.Element => (
  <>
    <PageBackground>
      <Img src={'stock/canalcity.png'} asDivWithBackground/>
      <LeadAndPageContainer>
        <OverlayArea>
          <OverlayWords>Client Focused</OverlayWords>
          <OverlayWords>|</OverlayWords>
          <OverlayWords>Integrity</OverlayWords>
          <OverlayWords>|</OverlayWords>
          <OverlayWords>Value Driven</OverlayWords>
        </OverlayArea>
        <PageSection mobileTop='8rem' desktopTop='10rem' marginBottom='3.0rem'>
          <PageSectionHeader marginTop='1.0rem'>The NHK <Highlight>Difference</Highlight></PageSectionHeader>
          <PageSectionParagraph>
            NHK Capital Partners structures investment opportunities in commercial real estate that are otherwise
            traditionally reserved for institutional investors. Utilizing the synergy of trust we have built with
            our investors and the experience and success of our network of developers, we are uniquely positioned
            to offer you a distinct opportunity to meet your investment needs.
          </PageSectionParagraph>
        </PageSection>
        <PageSection mobileTop='8rem' desktopTop='10rem'>
          <QuoteContainer>
            <PageImage src={'stock/handshake1.png'}/>
            <PatQuote>
              &quot;Take care of the client and we will both achieve <Highlight>success</Highlight>&quot;
              <br/>
              - Noreen Hogan
            </PatQuote>
          </QuoteContainer>
        </PageSection>
        <PageSection mobileTop='10rem' desktopTop='10rem'>
          <PageSectionHeader marginTop='2.0rem'>Our Team Knows How To <Highlight>Deliver</Highlight></PageSectionHeader>
          <PageSectionParagraph>
            NHK Capital Partners has begun establishing its own successful history in only the first few years since its inception.
            NHK was created to serve the requests from previous investors in other Hogan family companies, who wanted new investment
            vehicles to continue realizing success in other opportunities.. The Hogan family invests alongside our limited partners
            in each NHK offering, creating synergy and acknowledging deep faith in the merits of these opportunities. We have
            successfully raised $65,000,000 to date over the span of 5 funds, and expect to double that number over the next 12
            months. We have a deep history of working with elite developers on quality real estate projects.
          </PageSectionParagraph>
          <PageSectionParagraph>
            NHK&apos;s inaugural offering, <Link to='/investments/past/augusta-street-lofts'>The Augusta Flats</Link>, was a multi-family
            development in San Antonio, Texas. Investors who participated in this offering had a target term of 60 months, with a
            respective projected 12.2% IRR, and a 1.57X equity multiple. This project was completed and ultimately sold at month 32.
            This allowed our investors in this offering to receive a minimum 26% IRR and a 1.59X equity multiple.
          </PageSectionParagraph>
          <PageSectionParagraph>
            Our affiliate company, CMB Regional Centers (CMB), has raised over $3.1 billion in investment capital from foreign nationals
            seeking U.S. permanent residency through the EB-5 Investor Visa program. CMB has provided funding for projects in a vast
            variety of real estate categories: logistics, residential &amp; multi-family housing, hotel &amp; hospitality,
            resort &amp; amusement facilities, student housing, office &amp; mixed use, solar energy, and bio refinery developments.
            CMB has achieved a 100% project approval rate, delivering on the number one goal of EB-5 investors: a green card for
            themselves and their families, and has been able to successfully return over $1.6 billion in investment capital back to
            its limited partners
          </PageSectionParagraph>
        </PageSection>
        <PageSection mobileTop='8rem' desktopTop='10rem'>
          <NHKLocationBar/>
        </PageSection>
        <PageSection mobileTop='8rem' desktopTop='10rem'>
          <Footer/>
        </PageSection>
      </LeadAndPageContainer>
    </PageBackground>
  </>
);
