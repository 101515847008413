import * as React from 'react';
import styled from 'src/theme';
import {select} from 'd3-selection';
import {pie, arc} from 'd3-shape';
import {scaleOrdinal} from 'd3-scale';
import {schemeCategory10} from 'd3-scale-chromatic';

interface ID3DataArray {
  value: number;
}

export interface IDoughnutChartProps {
  width: number;
  height: number;
  innerRadius: number;
  outerRadius: number;
  data: Array<ID3DataArray>;
  legend: Array<string>;
  colors?: Array<string>;
}

const ChartAndLegendContainer = styled.div`
    position: relative;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-top: 1.5rem;
`;

const Chart = styled.div`
    position: relative;
    flex: 0 0 1;
`;

const ChartTotal = styled.div`
    position: absolute;
    top: 42%;
    left: 32%;
    font-size: 1.25rem;
    color: #000;
    ${(theme): string => theme.smallFont}

    @media screen and (min-width: 420px) {
        top: 43%;
        left: 31%;
    }
`;

const Legend = styled.div`
    flex: 0 0 1;
    align-self: center;
`;

const LegendList = styled.ul`
    list-style-position: outside;
`;

const LegendListBullet = styled.li`
    font-size: 2.0rem;
    line-height: 1.0rem;
    padding-bottom: 1.0rem;
    color: ${(props): string => props.color};

    & p {
        vertical-align: middle;
        display: inline-block;
    }
`;

const LegendListItem = styled.p`
    font-size: 1.0rem;
    color: #000;
    padding: 0px!important;
    ${(theme): string => theme.smallFont}
`;


export const DoughnutChart: React.FC<IDoughnutChartProps> = (props: IDoughnutChartProps): JSX.Element => {
  const ref = React.useRef(null);
  const createPie = pie<ID3DataArray>()
    .value((d: any) => d.value)
    .sort(null);
  // const createArc = arc<PieArcDatum<number>>()
  const createArc = arc<ID3DataArray>()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);
  const colors = scaleOrdinal((props.colors) ? props.colors : schemeCategory10);
  console.log('color scaleOrdinal ', colors);
  const dollars = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 12,
  });
  const totalInvested = (d: any): number => {
    let total = 0;
    d.map( (val: any) => {
      total += val.value;
    });
    return total;
  };

  React.useEffect(
    () => {
      const data = createPie(props.data);
      const group = select(ref.current);
      const groupWithData = group.selectAll('g.arc').data(data);

      groupWithData.exit().remove();

      const groupWithUpdate = groupWithData
        .enter()
        .append('g')
        .attr('class', 'arc');

      const path = groupWithUpdate
        .append('path')
        .merge(groupWithData.select('path.arc'));

      path
        .attr('class', 'arc')
        .attr('d', createArc)
        .attr('fill', (d: object, i: any) => {
          console.log('d is ', d);
          console.log('i color ', typeof i);
          const colorOut = colors(i);
          console.log('colorOut ', typeof colorOut);
          return colorOut;
        });
    },
    [colors, createArc, createPie, props.data],
  );

  return (
    <ChartAndLegendContainer>
      <Chart>
        <ChartTotal>
          {dollars.format(totalInvested(props.data))}
        </ChartTotal>
        <svg width={props.width} height={props.height}>
          <g
            ref={ref}
            transform={`translate(${props.width/2} ${props.height/2})`}
          />
        </svg>
      </Chart>
      <Legend>
        <LegendList>
          {props.data.map( (val: any, index: any) => {
            return (
              <LegendListBullet
                key={'leg' + val.value + index}
                color={
                  props.colors && props.colors[props.data.length - 1 - index] ||
                  colors(index)
                }
              >
                <LegendListItem>
                  {props.legend[props.data.length - 1 - index]}
                  <br/>
                  {
                    dollars.format(props.data[props.data.length - 1 - index].value)
                  }
                </LegendListItem>
              </LegendListBullet>
            );
          })}
        </LegendList>
      </Legend>
    </ChartAndLegendContainer>
  );
};

