export const isLive = () => process.env.NODE_ENV === 'production';
export const hostname = () => isLive() ? 'https://nhkcapitalpartners.com' : 'http://dev.nhkcapitalpartners.com';
export const contactPort = () => isLive() ? 443 :
  (API_URL.indexOf('localhost') === -1) ? 80 : 8001;
export const newsPort = () => isLive() ? 443 :
  (API_URL.indexOf('localhost') === -1) ? 80 : 2999;
export const projectsPort = () => isLive() ? 443 :
  (API_URL.indexOf('localhost') === -1) ? 80 : 2998;
export const ndaIaicPort = () => isLive() ? 443 :
  (API_URL.indexOf('localhost') === -1) ? 80 : 8010;
