import styled, {IGlobalThemeInterface} from 'src/theme';

export const DotTimeline = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const TimelineDate = styled.div`
  padding: 0 0.9rem;
  display: flex;
  justify-content: center;
  @media (min-width: 600px) {
    border-top: 2px solid rgba(0, 0, 0, 0.4);
  }

  position: relative;
  transition: all 200ms ease-in;
  padding-top: 1rem;

  &:before {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    top: -15px;
    transition: all 200ms ease-in;
  }
`;

export const TimelineLabel = styled.span`
  font-size: 1.15rem;
  ${({theme}): string => theme.headerFontThin}

  margin-bottom: 20px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
  text-align: center;

  font-style: normal;

  color: ${({theme}): string => theme.primaryDarkFontColor};
`;

export const TimelineElement = styled('li')`
  list-style-type: none;
  color: ${({theme}: IGlobalThemeInterface): string => theme.headerFont};
  font-style: italic;
  font-size: 1.5rem;

  padding: 0 0px;

  width: 145px;

  ${TimelineDate} {
    &:before {
      background-color: ${({color, theme}): string => color || theme.darkGold};
    }
  }
`;
