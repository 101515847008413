import * as React from 'react';

import {useCookie} from 'src/lib/useCookie';
import styled from 'src/theme';

interface IReg506cProps {
  children?: JSX.Element | JSX.Element[];
  style: React.CSSProperties;
}

const Reg506cBlur = styled.div`
  filter: blur(0.7rem);
  min-height: 15rem;
  z-index: 2000;
  pointer-events: none;
  user-select: none;
  * {
    pointer-events: none;
    user-select: none;
  }

  opacity: 0;

  .cssfilters & {
    opacity: 1;
  }
`;
const Reg506cFormContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const Reg506cContainer = styled.div`
  position: relative;
  max-width: 100vw;
`;

const Reg506cForm = styled.form`
  left: 0;
  right: 0;
  max-width: 620px;
  margin: auto;
  display: block;
  background-color: white;
  color: black;
  border-radius: 0.2rem;
  padding: 0.8rem;

  h2 {
    font-size: 1.4rem;
  }

  p, li {
    font-size: 1rem;
    line-height: 1.45rem;
    margin-bottom: 0.75rem;
  }

  ol {
    list-style: lower-alpha;
    margin: 0;
  }
`;

const Reg506cButton = styled.button`
  padding: 12px;
  width: 100%;
  max-width: 400px;
  background: rgba(230, 25, 25, 1);
  border-radius: 0.6em;
  border: 1px solid transparent;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 0.25em 0;
  cursor: pointer;
  color: white;
  margin-left: 50%;
  transform: translateX(-50%);

  ${({color}): string | void => color === 'secondary' && `
    color: black;
    background: transparent;
  ` || ''}

  z-index: 1000;
`;

export const Reg506c = ({children, style}: IReg506cProps): JSX.Element => {
  const [reg506cValidation, setReg506cValidation] = useCookie('reg506c_validation', false); // FIX IE11 here
  const [hidePrompt, setHidePrompt] = React.useState(false);

  console.log(reg506cValidation);

  const showProjectInfo = (): void => setReg506cValidation(true);
  const hideProjectInfo = (): void => setHidePrompt(true);

  if (reg506cValidation) {
    return children;
  } else {
    const FormContainer = (
      <Reg506cFormContainer>
        <Reg506cForm>
          <h2>Are You An Accredited Investor?</h2>
          <p>To qualify as an “accredited investor” you must:</p>
          <ol>
            <li>
              Have an individual income of more than $200,000 per year, or a joint income of
              $300,000 with your spouse, in each of the last two years and reasonably expect
              to maintain the same level of income for the current year, OR
            </li>
            <li>
              Have a net worth exceeding $1 million, either individually or jointly with your
              spouse, not including your primary residence.
            </li>
          </ol>
          <p>
            At this time, we are only able to show project information online to accredited investors.
          </p>
          <Reg506cButton
            onClick={showProjectInfo}
          >
            Yes, show me the project information
          </Reg506cButton>
          <Reg506cButton
            color='secondary'
            onClick={hideProjectInfo}
          >
            No, I will contact you
          </Reg506cButton>
        </Reg506cForm>
      </Reg506cFormContainer>
    );
    return (
      <Reg506cContainer style={style}>
        <Reg506cBlur>
          {children}
        </Reg506cBlur>
        {!hidePrompt && FormContainer}
      </Reg506cContainer>
    );
  }
};
