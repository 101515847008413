import * as React from 'react';
import {Img} from 'src/components/Img';
import {NHKLogo} from 'src/components/NHKLogo';
import {Highlight} from 'src/components/Highlight';
import styled from 'src/theme';

const LocationBarContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    justify-content: space-evenly;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3.0rem;
`;

const LocationBarLogo = styled.div`
    flex: 0 0 2;
    align-self: flex-start;
`;

const LocationBarAddresses = styled.div`
    flex: 1 0 1;
`;

const AddressesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: space-evenly;
`;

const Address = styled.div`
    margin-top: 1.0rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
    flex: 1;
`;

const Info = styled.div`
    flex: 1;
`;

const MailTo = styled.a`
    color: black;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const LocationBarMap = styled.div`
    flex: 1 0 3;
`;

const DallasMap = styled(Img)`
    width: 100%;
    max-width: 100%;
    height: auto;
`;

export const NHKLocationBar = (): JSX.Element => {
  return (
    <LocationBarContainer>
      <LocationBarLogo>
        <NHKLogo large dark/>
      </LocationBarLogo>
      <LocationBarAddresses>
        <AddressesContainer>
          <Address>
            <Highlight>Headquarters</Highlight>
            <br/>
                        5910 N Central Expressway
            <br/>
                        Suite #1000
            <br/>
                        Dallas, TX  75206
            <br/>
                        (469) 638-8801
          </Address>
          <Info>
            <MailTo href='mailto:info@nhkcapitalpartners.com'>
                            info@nhkcapitalpartners.com
            </MailTo>
          </Info>
        </AddressesContainer>
      </LocationBarAddresses>
      <LocationBarMap>
        <a href='https://goo.gl/maps/xJnjE1F7sM3xfvKQ7' target='_blank' rel='noopener noreferrer'>
          <DallasMap src='corporate/dallas-loc-map.png'/>
        </a>
      </LocationBarMap>
    </LocationBarContainer>
  );
};
