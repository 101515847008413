import * as React from 'react';
import styled from 'src/theme';
import {Link} from 'src/components/Link';
import {NHKLogo} from 'src/components/NHKLogo';

const NHKHeader = styled.header`
    background-color: #000;
    background-color: rgba(0, 0, 0, 65%);
    color: #dedcb9;
    padding: 0.5em 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: 5;
`;

const HeaderContent = styled.div`
    display: flex;
    width: 100%;
    vertical-align: middle;
`;

const LogoArea = styled.div`
    display: block;
    padding: 0.5rem 0 0 0;
    width: 4.0rem;

    @media screen and (min-width: 735px) {
        width: 6.0rem;
        padding: 0.5rem 0 0 0.5rem;
    }
`;

const NavArea = styled.nav`
    display: inherit;
    text-align: center;
    width: 80%;
`;

const NavLinksList = styled.ul`
    list-style-type: none;
    display: flex;
    flex-basis: 25%;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-inline-start: 0.1rem;

    @media screen and (min-width: 735px) {
        padding-left: 2.5rem;
    }
`;

const HomeNavItem = styled.li`
    display: none;

    @media screen and (min-width: 735px) {
        display: inherit;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
`;

const NavItem = styled.li`
    display: inherit;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
`;

const HomeNavItemSeperator = styled.li`
    display: none;

    @media screen and (min-width: 735px) {
        display: inherit;
        background-color: #fff;
        width: 0.1rem;
        height: 1.0rem;
        font-size: 1.5rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
`;

const NavItemSeperator = styled.li`
    background-color: #fff;
    width: 0.1rem;
    height: 1.0rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    
    @media screen and (min-width: 735px) {
        font-size: 1.5rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
`;

const NavLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-family: Cinzel,serif;
    font-weight: 300;
    font-size: 0.75rem;
    transition: color 0.3s ease;
    
    &:hover,&:focus {
        color: ${(props): string => props.theme.highlight};
    }

    @media screen and (min-width: 550px) {
        font-size: 1.1rem;
    }

    @media screen and (min-width: 735px) {
        font-size: 1.5rem;
    }
`;

const ActivePage = styled.span`
    color: ${(props): string => props.theme.highlight};
    text-decoration: none;
    font-family: Cinzel,serif;
    font-weight: 300;
    font-size: 0.75rem;
    
    @media screen and (min-width: 735px) {
        font-size: 1.5rem;
    }
`;

const LoginButton = styled.a`
    text-decoration: none;
    position: absolute;
    right: 4px;
    top: 0;
    padding: 2px 9px;
    line-height: 1.5;
    background: rgb(230, 25, 25);
    border-radius: 0.6em;
    border: 1px solid transparent;
    box-shadow: rgb(0 0 0 / 60%) 0px 3px 6px 0px;
    margin: 0.25em 0px;
    cursor: pointer;
    color: white;
    font-size: 0.9rem;

    &:disabled {
        background: rgba(134, 109, 51, 0.8);
        box-shadow: none;
    }

    &:hover {
        box-shadow: none;
    }

    @media screen and (min-width: 768px) {
        padding: 4px 15px;
    }
    @media screen and (min-width: 1024px) {
        padding: 4px 19px;
        font-size: 1rem;
        right: 8px;
        top: 4px;
    }
`;

export const Header = (props: any): JSX.Element => (
  <NHKHeader>
    <LoginButton href='https://nhkcapitalpartners.sharefile.com/Authentication/Login'>Log In</LoginButton>
    <HeaderContent>
      <LogoArea>
        {props.path != '/' && <NavLink to='/'><NHKLogo src={'corporate/nhk-logo.png'}/></NavLink> ||
                 <NHKLogo/>}
      </LogoArea>
      <NavArea>
        <NavLinksList>
          <HomeNavItem>
            {props.path != '/' && <NavLink to='/'>Home</NavLink> ||
                        <ActivePage>Home</ActivePage>}
          </HomeNavItem>
          <HomeNavItemSeperator/>
          <NavItem>
            {props.path != '/about' && <NavLink to='/about'>About</NavLink> ||
                        <ActivePage>About</ActivePage>}
          </NavItem>
          <NavItemSeperator/>
          <NavItem>
            {props.path != '/investments' && <NavLink to='/investments'>Investments</NavLink> ||
                        <ActivePage>Investments</ActivePage>}
          </NavItem>
          <NavItemSeperator/>
          <NavItem>
            {props.path != '/contact' && <NavLink to='/contact'>Contact</NavLink> ||
                        <ActivePage>Contact</ActivePage>}
          </NavItem>
          <NavItemSeperator/>
          <NavItem>
            {props.path != '/news' && <NavLink to='/news'>News</NavLink> ||
                        <ActivePage>News</ActivePage>}
          </NavItem>
        </NavLinksList>
      </NavArea>
    </HeaderContent>
  </NHKHeader>
);
