import * as React from 'react';
import {Route} from 'react-router-dom';
import slugify from 'slugify';

import {Link} from 'src/components/Link';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  PageSectionMinorHeader,
} from 'src/components/PageSection';
import {projects} from 'src/data/projects';
import {IProject} from 'src/lib/IProject';
import {Footer} from './Footer';
import {Project} from './Project';

interface IProjectSectionProps {
  project: IProject;
}
const ProjectSection = ({project}: IProjectSectionProps): JSX.Element => (
  <div>
    <Link scrollToTop={true} to={`/portfolio/${slugify(project.name).toLowerCase()}`}>
      {project.name}
    </Link>
  </div>
);

const CURRENT_PROJECTS = projects.filter((x) => x.status === 'CURRENT');

const MainPortfolioPage = (): JSX.Element => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader>NHK Subscription Portfolio</PageSectionHeader>
        <PageSectionMinorHeader>CURRENT OFFERINGS</PageSectionMinorHeader>
        {CURRENT_PROJECTS.map((project) => <ProjectSection key={project.name} project={project}/>)}
      </PageSection>
    </PageBackground>
    <Footer/>
  </>
);

const getRouteForProject = (project: IProject): JSX.Element => {
  const render = (): JSX.Element => <Project project={project}/>;
  return (
    <Route
      key={project.name}
      path={`/portfolio/${slugify(project.name)}`}
      render={render}
    />
  );
};

export const PortfolioPage = (): JSX.Element => (
  <>
    <Route exact={true} path={'/portfolio'} render={MainPortfolioPage}/>
    {projects.map(getRouteForProject)}
  </>
);
