import {responsive} from 'src/lib/responsive';
import styled, {css} from 'src/theme';

export interface IContainerPropTypes {
  padding?: number;
}

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${responsive('sm', `
    flex-direction: row;
  `)}
`;

export interface IColumnProps {
  grow: number;
  basis: string;
}

export const Column = styled.div`
  flex-grow: ${({grow}: IColumnProps) => grow !== undefined && grow || 1};
  flex-basis: auto;
  ${responsive('sm', css`
    flex-basis: 0;
  `)}
  display: flex;
  flex-direction: column;
`;
