import * as React from 'react';
import styled from 'src/theme';
import {Img} from 'src/components/Img';

export interface INHKLogoProps {
  src?: string;
  large?: boolean;
  dark?: boolean;
}

const NHKLogoImg = styled(Img)`
    max-width: 100%;
    height: auto;
`;

export const NHKLogo = (props: INHKLogoProps): JSX.Element => {
  let source;

  if ( props.large && props.dark ) {
    source = 'corporate/nhk-logo_200_dark.png';
  } else if (!props.large && props.dark) {
    source = 'corporate/nhk-logo_150_dark.png';
  } else {
    source = 'corporate/nhk-logo.png';
  }
  return (
    <NHKLogoImg src={source}/>
  );
}
;
