import {IProject} from 'src/lib/IProject';
import {AugustaStreetLofts} from './AugustaStreetLofts';

export const projects: IProject[] = [{
  name: 'Augusta Street Lofts',
  locationLine1: '819 Augusta St.',
  locationLine2: 'San Antonio, TX 78215',
  status: 'CURRENT',
  content: AugustaStreetLofts,
}];
