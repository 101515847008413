import * as React from 'react';

import {
  PageBackground,
  PageSection,
  PageSectionHeader,
} from 'src/components/PageSection';
import {Footer} from './Footer';

export const PressKitPage = () => (
  <>
    <PageBackground>
      <PageSection>
        <PageSectionHeader>Press Releases</PageSectionHeader>
      </PageSection>
    </PageBackground>
    <Footer/>
  </>
);
