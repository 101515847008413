import * as React from 'react';
import {Highlight} from 'src/components/Highlight';
import {Img} from 'src/components/Img';
import { Link } from 'src/components/Link';
import styled, {theme} from 'src/theme';

const FooterDisclaimerParagraph = styled.p`
  margin-top: 2.0rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: justify;
  display: block;
  color: rgba(235, 235, 235, 0.95);
  text-shadow: 1px 1px 4px #000000FF;
  ${theme.regularFont};
`;
const FooterDisclaimerLink = styled.a`
  margin-top: 2.0rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: justify;
  color: rgba(235, 235, 235, 0.95);
  text-shadow: 1px 1px 4px #000000FF;
  text-decoration: underline;
  ${theme.regularFont};
`;

const LocationsAndSocials = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Location = styled.div`
  flex: 0 0 1;
  color: #fff;
  margin-top: 1.0rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
`;

const Socials = styled.div`
  font-size: 0.8rem;
  color: #fff;
  flex: 0 0 2;
  & > div {
    text-align: center;
  }
`;

const Copyright = styled.div`
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
`;

const FooterContainer = styled.footer`
  background-color: #000;
`;

export const Footer = (): JSX.Element => (
  <FooterContainer>
    <LocationsAndSocials>
      <Location>
        <Highlight>Headquarters</Highlight>
        <br/>
        5910 N Central Expy
        <br/>
        Dallas, TX  75206
        <br/>
        (469) 638-8801
      </Location>
      <Location>
        <Highlight>Midwest Office</Highlight>
        <br/>
        7819 42nd St W
        <br/>
        Rock Island, IL  61201
        <br/>
        (309) 283-4277
      </Location>
      <Socials>
        <span>info@nhkcapitalpartners.com</span>
        <div>
          <a href='https://www.facebook.com/NHK-Capital-Partners-2166010060314878/' target='_blank' rel='noopener noreferrer'>
            <Img src='corporate/nhk-facebook_30x30.png'/>
          </a>
          <a href='https://www.linkedin.com/company/nhk-capital-partners' target='_blank' rel='noopener noreferrer'>
            <Img src='corporate/nhk-linkedin_30x30.png'/>
          </a>
          {// <Img src='corporate/nhk-twitter_30x30.png'/>
          }
          <a href='https://www.linkedin.com/company/nhk-capital-partners' target='_blank' rel='noopener noreferrer'>
            <Img src='corporate/nhk-instagram_30x30.png'/>
          </a>
          {// <Img src='corporate/nhk-youtube_30x30.png'/>
          }
        </div>
      </Socials>
    </LocationsAndSocials>
    <FooterDisclaimerParagraph>
      <strong>DISCLAIMER:</strong> THIS IS NOT AN OFFER TO SELL SECURITIES OR THE SOLICITATION OF AN OFFER
      TO PURCHASE SECURITIES. ANY OFFER TO PARTICIPATE IN ANY SPONSORED PROJECT MAY ONLY BE MADE PURSUANT
      TO A WRITTEN OFFERING MEMORANDUM. ANY SALE IN A SPONSORED PROJECT SHALL BE EVIDENCED BY A SUBSCRIPTION AGREEMENT
      EXECUTED BY A FOREIGN NATIONAL AND WILL BE OFFERED AND SOLD, TO THE EXTENT APPLICABLE, BOTH
      WITHIN AND OUTSIDE OF THE UNITED STATES IN RELIANCE ON EXEMPTIONS FROM REGISTRATION UNDER THE
      SECURITIES ACT, STATE LAWS AND THE LAWS OF JURISDICTIONS WHERE THE OFFERING WILL BE MADE.
    </FooterDisclaimerParagraph>
    <FooterDisclaimerParagraph>
      <FooterDisclaimerLink href='https://transparency-in-coverage.uhc.com/'>Healthcare Transparency in Coverage</FooterDisclaimerLink>
      {' | '}
      <Link to='/privacy-policy'><FooterDisclaimerLink>Privacy Policy</FooterDisclaimerLink></Link>
    </FooterDisclaimerParagraph>
    <Copyright>&copy; NHK Capital Partners {new Date().getFullYear()}</Copyright>
  </FooterContainer>
);
